import { DataTable } from "simple-datatables";
import Swal from 'sweetalert2';


// Main admin listing page sorting
(function () {
	let el = document.querySelectorAll('.pendingEventList.listing table, .upcomingEventList.listing table');

	if (el === null) { return }

	el.forEach(e => init(e));

	function init(el) {
		const dataTable = new DataTable(el, {
			searchable: true,
			paging: false,
			columns: [
				{ select: 2, sort: "asc", type: "date", format: "MM/DD/YYYY" },
			]
		});
		$(el).find('thead tr th').each(function () {
			$(this).width('');
		})
	}

	//have to do this since simple-dt captures all click events inside its' container
	document.addEventListener('click', function (e) {
		for (let t = e.target; t && t != this; t = t.parentNode) {
			if (t.matches('li[data-tooltip="Approve"]')) {
				ApproveEvent(t);
				break
			}
			else if (t.matches('li[data-tooltip="Delete"]')) {
				DeleteEvent(t);
				break
			}
			else if (t.matches('li[data-tooltip="Request Approval"]')) {
				RequestApproval(t);
				break
			}
		}
	}, false);

	function ApproveEvent(el) {
		var $this = $(el),
			myId = $this.parents('tr').data('eventid'),
			data = { eid: myId };

		data = JSON.stringify(data);

		Swal.fire({
			icon: "question",
			title: "Approve Event?",
			html: "Are you sure you want to approve this event?",
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonText: '<span class="icon icon-checkmark"></span>Approve Event',
			cancelButtonText: 'Never mind',
			confirmButtonColor: '#7aad56',
			cancelButtonColor: '#4cabea',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: 'Approving Event',
					html: 'please wait...<br>Please do not navigate away from the page, click the back button, or close the window! <img src="../../img/loading-dots.gif" style="width: 80px; display: block; margin: 0 auto;"></img>',
					showConfirmButton: false,
				});

				$.ajax({
					url: approveeventurl + '?eid=' + myId,
					type: 'POST',
					contentType: "application/json; charset=utf-8",
					data: data,
					success: function (result) {
						console.log('result', result);

						if (result.IsError !== false) {
							//remove overlay
							$('.approvalWarning').removeClass('show');
							$('html').removeClass('noscroll');

							var myRow = $this.parents('tr').clone();
							$('.upcomingEventList tbody').append(myRow);
							myRow.find('td').last().find('li').not('[data-tooltip="View"]').remove();

							var checkinButton = $('<li data-tooltip="Check In"><a class="icon icon-user-check" title="Check In Registrants" target="_blank" href="/events/checkin/?eid=' + myId + '">Check In</a></li>');

							myRow.find('ul').append(checkinButton);

							$this.parents('tr').remove();
							$this.off('click');

							Swal.fire({
								icon: "success",
								showCloseButton: true,
								title: 'Event Approved!',
								showConfirmButton: false,
							});
						} else {
							Swal.fire({
								icon: "error",
								showCloseButton: true,
								title: 'Event Approval Failed',
								showConfirmButton: false,
							});
						}
					}
				});
			}
		});
	}

	function DeleteEvent(el) {
		var $this = $(el),
			myId = $this.parents('tr').data('eventid'),
			data = { eid: myId };

		data = JSON.stringify(data);

		Swal.fire({
			icon: "question",
			title: "Delete Event?",
			html: "Are you sure you would like to delete this event?\nWARNING: This action cannot be undone",
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonText: '<span class="icon icon-trash"></span>Delete Event',
			cancelButtonText: 'Never mind',
			confirmButtonColor: '#ef4035',
			cancelButtonColor: '#4cabea',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: 'Deleting Event',
					html: 'please wait... <img src="../../img/loading-dots.gif" style="width: 80px; display: block; margin: 0 auto;"></img>',
					showConfirmButton: false,
				});

				$.ajax({
					url: deleteeventurl + '?eid=' + myId,
					type: 'POST',
					success: function (result) {
						data = data;
						if (data.IsError !== false) {
							$this.parents('tr').remove();

							Swal.fire({
								icon: "success",
								showCloseButton: true,
								title: 'Event Deleted.',
								showConfirmButton: false,
							});
						} else {
							Swal.fire({
								icon: "error",
								showCloseButton: true,
								title: 'Event Deletion Failed',
								showConfirmButton: false,
							});
						}
					}
				});
			}
		});
	}

	function RequestApproval(el) {
		var $this = $(el),
			myId = $this.parents('tr').data('eventid'),
			data = { eid: myId };

		data = JSON.stringify(data);

		Swal.fire({
			icon: "question",
			title: "Request Approval?",
			html: "Are you sure you want to request approval for this event?",
			showCloseButton: true,
			showCancelButton: true,
			confirmButtonText: '<span class="icon icon-checkmark"></span>Request Approval',
			cancelButtonText: 'Never mind',
			confirmButtonColor: '#7aad56',
			cancelButtonColor: '#4cabea',
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: 'Requesting Approval',
					html: 'please wait...<br>Please do not navigate away from the page, click the back button, or close the window! <img src="../../img/loading-dots.gif" style="width: 80px; display: block; margin: 0 auto;"></img>',
					showConfirmButton: false,
				});

				$.ajax({
					url: submitforapprovalurl + '?eid=' + myId,
					type: 'POST',
					contentType: "application/json; charset=utf-8",
					data: data,
					success: function (result) {
						console.log('result', result);

						if (result.IsError !== false) {
							$this.off('click');
							$this.parents('tr').find('.notSumbittedMsg').remove();
							$this.remove();

							Swal.fire({
								icon: "success",
								showCloseButton: true,
								title: 'Approval Requested!',
								showConfirmButton: false,
							});
						} else {
							Swal.fire({
								icon: "error",
								showCloseButton: true,
								title: 'Event Approval Request Failed',
								showConfirmButton: false,
							});
						}
					}
				});
			}
		});
	}

	var theTable,
		ignoreHeading,
		useUTC;

	function swEventsUtilTableSortrInit(opts) {
		opts = opts || {};
		theTable = opts.tableSelector || 'table.sortable';
		ignoreHeading = opts.ignoreHeading || '.sortIgnore';
		swEventsUtilTableSortrSetup();
	}

	function swEventsUtilTableSortrSetup() {
		$(theTable).each(function () {
			$(this).find('thead th').not(ignoreHeading).on('click', function () {
				var $this = $(this),
					colIdx = $this.index(),
					toSort = [],
					curTblBod = $this.parents('table').find('tbody'),
					newTblBod = $('<tbody>');

				if ($(this).attr('data-utc')) {
					useUTC = true;
				}

				$this.toggleClass('toggle');

				curTblBod.find('tr').each(function (k, v) {
					if (useUTC === true) {
						toSort.push([$(this).children('td').eq(colIdx).attr('data-utc'), $(this).index()]);
					} else {
						toSort.push([$(this).children('td').eq(colIdx).text(), $(this).index()]);
					}
				});

				toSort.sort();

				if ($(theTable).data('sorted') === 'descending') {
					toSort.reverse();
					$(theTable).data('sorted', 'ascending');
				} else {
					$(theTable).data('sorted', 'descending');
				}

				console.log($(theTable).data('sorted'));
				console.log("toSort: ", toSort);

				$.each(toSort, function (k, v) {
					curTblBod.find('tr').eq(v[1]).clone(true).appendTo(newTblBod);
				});
				curTblBod.replaceWith(newTblBod);
			});
		});
	};

	$('.view-past-events').on('click', function (e) {
		e.preventDefault();
		$.ajax({
			url: getpastevents,
			type: 'POST',
			beforeSend: function () {
				$('.view-past-events').text('Loading past events...');
			}
		})
			.success(function (result) {
				$('.pastEventList').html(result);
				console.log('success');
				swEventsUtilTableSortrInit({
					tableSelector: 'table'
				})

				let el = document.querySelector('.pastEventList.listing table');
				if (el === null) { return }
				init(el);
			})
			.error(function (status) {
				$('.view-past-events').text('Failed to load past events');
				console.log(status);
			});
	});
}());
