import Swal from 'sweetalert2';

(function () {
	let cancelButtonEl = document.querySelector('a[data-context="cancel-event"]');

	if (cancelButtonEl == undefined) { return }

	let eventName = document.querySelector('.eventName').textContent;

	init(cancelButtonEl);

	function init(cancelButtonEl) {
		cancelButtonEl.addEventListener('click', function (e) {
			e.preventDefault();

			Swal.fire({
				icon: "warning",
				title: "Cancel Event - " + eventName + "?",
				html: "The event will be deleted from the system, a notification of the cancelation will be sent to registrants, &amp; the registrant list will no longer be available.",
				input: 'text',
				inputLabel: 'Type CONFIRM to cancel',
				inputValue: '',
				showCloseButton: true,
				showCancelButton: true,
				focusConfirm: false,
				confirmButtonText: '<span class="icon icon-trash"></span> Ok, cancel event',
				cancelButtonText: 'Never mind',
				confirmButtonColor: '#ef4035',
				cancelButtonColor: '#4cabea',
				inputValidator: (value) => {
					if (value !== 'CONFIRM') {
						return 'You must type CONFIRM to verify cancel request!'
					}
				},				
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: 'Canceling event',
						html: 'Please wait...<img src="../../img/loading-dots.gif" style="width: 80px; display: block; margin: 0 auto;"></img>',
						showConfirmButton: false,
					});

					window.location = cancelurl;
				}				
			});
		});
	}
}());