import moment from "moment";

	var swEvents = {
		util: {
			apiKeys: {
				geocode: 'AIzaSyD4hXcwuj6uQm-eiarXpORcGGPi7oLKGkU',
				map: 'AIzaSyBzq61x1o3AsxJKvX5VVv5cPiU3hN6_WTM'
			},
			actionButton: (function () {
				var selector,
					ajaxCall,
					$body = $('body'),
					options;
				$.ajaxSetup({
					beforeSend: function (xhr, settings) {
						if ($(document.activeElement).attr('data-calls-ajax') === 'true' && settings.url.indexOf('umbraco') > 0) {
							$(document.activeElement).trigger('processing').addClass('ajaxCaller');
						}
					},
					complete: function (xhr) {
						if (typeof xhr.responseJSON === 'string') {
							$('.ajaxCaller').trigger('processingComplete').removeClass('ajaxCaller');
						}
					}
				});
				var init = function () {
					$(selector).each(function () {
						var $this = $(this);
						if (options.ajaxCaller === true) { $this.attr('data-calls-ajax', 'true').data('data-calls-ajax', 'true'); }
						$this.on('processing', function () {
							var $me = $(this);
							$body.addClass('processing');
							$me.attr('data-original-text', $me.html()).text('Working...');
						}).on('processingComplete', function () {
							var $me = $(this);
							$body.removeClass('processing');
							$me.html($me.attr('data-original-text'));
						}).on('click', function () {
							if ($(this).attr('data-calls-ajax') !== 'true') { $(this).trigger('processing'); }
						});
					});
				};
				return {
					register: function (opts) {
						opts = opts || {};
						options = opts;
						selector = opts.selector;
						ajaxCall = opts.ajaxCall || false;
						init();
						return this;
					}
				};
			})(),
			pointsBreak: (function () {
				var time = 200, W, H,
					runThis = function (fin) {
						if (typeof fin === 'function') { fin(); }
					},
					breakPointTest = function (bpTest) {
						var type = bpTest.charAt(0) === 'W' ? W : H,
							test = bpTest[1],
							dims = bpTest.split(bpTest.charAt(1))[1];
						return test === '>' && type > dims || test === '<' && type < dims ? true : false;
					},
					doWork = function (context) {
						var andReg = /&&+/g;
						W = window.innerWidth || document.documentElement.clientWidth,
							H = window.innerHeight || document.documentElement.clientHeight;
						for (var i in context) {
							if (i.charAt(0) === 'W' || i.charAt(0) === 'H') {
								i = i.replace(/\s+/g, '').toUpperCase();
								var f = 0,
									myFunc = context[i];
								if (i.match(andReg) !== null) {
									var tests = i.split(/&&/);
									for (var x in tests) {
										if (tests[x].charAt(0) === 'W' || tests[x].charAt(0) === 'H') {
											if (breakPointTest(tests[x]) === false) { f = false; }
										}
									}
									if (f !== false) { runThis(myFunc(W, H)); }
								} else {
									if (breakPointTest(i)) { runThis(myFunc(W, H)); }
								}
							}
						}
					};
				return {
					init: function (context) {
						var doIt;
						doWork(context);
						window.onresize = function () {
							clearTimeout(doIt);
							doIt = setTimeout(function () { doWork(context); }, time);
						};
						return this;
					},
					setDelay: function (x, finFunc) {
						time = x;
						return this;
					},
					getWidth: function () { return W; },
					getHeight: function () { return H; }
				};
			})(),
			validate: {
				email: function (email) {
					return email.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i) ? true : false;
				},
				postal: function (postalCode, el) {
					postalCode = postalCode.toUpperCase();
					var country = el.parents('form').find('#country').val();
					return country === 'US' && postalCode.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) ? true : country === 'CA' && postalCode.match(/^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/) ? true : false;
				},
				postalNotRequired: function (postalCode, el) {
					postalCode = postalCode.toUpperCase();
					var country = el.parents('form').find('#country').val();

					if (country === 'US' && postalCode.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) {
						return true;
					} else if (country === 'CA' && postalCode.match(/^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/)) {
						return true;
					} else if (postalCode === '') {
						return true;
					} else {
						return false;
					}

					//return country === 'US' && postalCode.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) ? true : country === 'CA' && postalCode.match(/^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/) ? true : false;
				},
				notEmpty: function (value) {
					return value !== '' && value !== null && value !== undefined;
				},
				selected: function (value, el) {
					return el[0].options.selectedIndex !== 0;
				},
				date: function (value) {
					return value.match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/);
				},
				time: function (value) {
					return value.match(/^(\d{1,2}):(\d{2})([ap]m)?$/);
				},
				selectedOption: function (val, el) {
					var opts = el.prop('name'),
						optChosen = false;
					$('[name="' + opts + '"]').each(function () {
						if ($(this).is(':checked')) { optChosen = true; }
					});
					return optChosen;
				}
			},
			getDate: function () {
				var today = new Date();
				var dd = today.getDate();
				var mm = today.getMonth() + 1;

				var yyyy = today.getFullYear();
				if (dd < 10) {
					dd = '0' + dd;
				}
				if (mm < 10) {
					mm = '0' + mm;
				}
				today = dd + '/' + mm + '/' + yyyy;
				return today;
			},
			initDeleteEventButton: function () {
			},
			tableSortr: (function () {
				var theTable,
					ignoreHeading,
					useUTC,
					setup = function () {
						$(theTable).each(function () {
							$(this).find('thead th').not(ignoreHeading).on('click', function () {
								var $this = $(this),
									colIdx = $this.index(),
									toSort = [],
									curTblBod = $this.parents('table').find('tbody'),
									newTblBod = $('<tbody>');

								if ($(this).attr('data-utc')) {
									useUTC = true;
								}

								$this.toggleClass('toggle');

								curTblBod.find('tr').each(function (k, v) {
									if (useUTC === true) {
										toSort.push([$(this).children('td').eq(colIdx).attr('data-utc'), $(this).index()]);
									} else {
										toSort.push([$(this).children('td').eq(colIdx).text(), $(this).index()]);
									}
								});

								toSort.sort();

								if ($(theTable).data('sorted') === 'descending') {
									toSort.reverse();
									$(theTable).data('sorted', 'ascending');
								} else {
									$(theTable).data('sorted', 'descending');
								}

								console.log($(theTable).data('sorted'));
								console.log("toSort: ", toSort);

								$.each(toSort, function (k, v) {
									curTblBod.find('tr').eq(v[1]).clone(true).appendTo(newTblBod);
								});
								curTblBod.replaceWith(newTblBod);
							});
						});
					};
				return {
					init: function (opts) {
						opts = opts || {};
						theTable = opts.tableSelector || 'table.sortable';
						ignoreHeading = opts.ignoreHeading || '.sortIgnore';
						setup();
					}
				};
			})(),
			csvDownloader: (function () {
				var dlBtn,
					table,
					fileName,
					csv = '',
					setupBtn = function (data) {
						$(dlBtn).attr({
							'href': 'data:application/text;charset=utf-8,' + encodeURIComponent(data),
							'target': '_blank',
							'download': fileName
						});
						if (window.navigator.userAgent.search('Trident') >= 0 || window.navigator.userAgent.search('Edge')) {
							$(dlBtn).on('click', function (e) {
								$('body').append('<iframe id="csvFrame" style="display: none;"></iframe>');
								var iframe = $('#csvFrame')[0];
								iframe = iframe.contentWindow || iframe.contentDocument;
								data = 'sep=,\r\n' + data;
								iframe.document.open('text/html', 'replace');
								iframe.document.write(data);
								iframe.document.close();
								iframe.focus();
								iframe.document.execCommand('SaveAs', true, 'Attendees.csv');
								if (window.navigator.userAgent.search('Edge')) {
									var blobObject = new Blob([data]);
									window.navigator.msSaveOrOpenBlob(blobObject, "Attendees.csv");
								}
								$('#csvFrame').remove();
								e.preventDefault();
							});
						}
					},
					makeCsv = function () {
						$(table).find('thead th').each(function (i) {
							csv += '"' + $(this).text() + '"' + (i === ($(table).find('thead th').length - 1) ? '\r\n' : ',');
						}).end().find('tbody tr').each(function (i) {
							$(this).find('td').each(function (i) {
								csv += '"' + $(this).text() + '"' + (i === ($(this).parent('tr').find('td').length - 1) ? '\r\n' : ',');
							});
						});
						setupBtn(csv);
					};
				return {
					init: function (opts) {
						opts = opts || {};
						dlBtn = opts.downloadButton || '.csvDownload';
						table = opts.table || '.csvData';
						fileName = opts.fileName || 'CSV-Download';
						makeCsv();
					}
				};
			})(),
			eqHeights: (function () {
				var selector, plusSome;
				var getHeight = function (selector) {
					$(selector).height('');
					var theGroup = [], setter = 0;
					$(selector).each(function () {
						theGroup.push($(this).innerHeight().toFixed());
						setter = Math.max.apply(Math, theGroup);
					});
					return setter;
				};
				return {
					set: function (options, cb) {
						selector = options.selector || '.eqHeight';
						plusSome = options.plusSome || false;
						plusSome ? $(selector).height(getHeight(selector) + plusSome) : $(selector).height(getHeight(selector));
						if (typeof cb === 'function') { cb(); }
					}
				};
			})(),
			//loadChart: function(opts){
			//	if(!$(opts.el).parent().children('p').length){
			//		$(opts.el).before('<p>' + opts.nums[0] + ' out of ' + opts.nums[1] + '</p>');
			//	}
			//	$(opts.el).text(opts.nums[0] + '/' + opts.nums[1]).peity('donut', {
			//		width: opts.width,
			//		height: opts.height,
			//		fill: ['#ffffff', '#0067B1'],
			//		innerRadius: opts.innerRadius || 0
			//	});
			//},
			gMapsHelpers: {
				convertToMeters: function (num) {
					return num * 1609.344;
				},
				formatAddressForGmaps: function (address) {
					return address.replace(/\s/g, '+').replace(/#/g, 'Suite+');
				},
				createMarker: function (opts) {
					swEvents.util.jQueryExtends.kThen();
					opts.markers[opts.markerCount] = new google.maps.Marker({
						position: {
							lat: parseFloat(opts.lat),
							lng: parseFloat(opts.lng)
						},
						map: opts.map,
						title: opts.eventName
					});
					if (opts.showInfo === true) {
						opts.markers[opts.markerCount].addListener('click', function () {
							$('html,body').animate({ scrollTop: $('.map').offset().top - 50 }, 200, function () {
								opts.map.setZoom(8);
								opts.map.setCenter(opts.markers[opts.markerCount].getPosition());
								opts.info.open(opts.map, opts.markers[opts.markerCount]);
							});
						});
					} else if (opts.showInfo === false && typeof opts.customEvent === 'function') {
						opts.markers[opts.markerCount].addListener('click', function () {
							opts.map.setZoom(8);
							opts.map.setCenter(opts.markers[opts.markerCount].getPosition());
							opts.customEvent(opts.markers[opts.markerCount]);
						});
					}
				},
				getLocationLatLong: function (address, cb) {
					var latlong;
					jQuery.support.cors = true;
					var geocoder = new google.maps.Geocoder();

					geocoder.geocode({ 'address': address }, function(results, status) 
					{
						if (status==='OK') {
							latlong = {
								lat: parseFloat(results[0].geometry.location.lat()),
								lng: parseFloat(results[0].geometry.location.lng())
							};

							cb(latlong);
						}
						else {
							console.log("GMaps Geocode ERROR");
						} 
					});
				},
				getMapImgUrl: function (opts) {
					opts = opts || {};
					var formatedAddr = this.formatAddressForGmaps(opts.addr);
					return 'https://maps.googleapis.com/maps/api/staticmap?center=' + formatedAddr + '&zoom=' + opts.zoom + '&size=' + opts.size + '&maptype=roadmap&markers=color:' + opts.color + '%7C' + formatedAddr + `&key=${swEvents.util.apiKeys.map}`;
				}
			},
			jQueryExtends: {
				modals: function () {
					$.extend($.fn, {
						showModal: function (opts, cb) {
							typeof opts === 'function' && arguments.length === 1 ? cb = opts : opts = opts || {};
							var $modal = $(this),
								bgColor = opts.bgColor || 'rgba(0,0,0,.7)',
								bgSpeed = opts.bgSpeed || 500,
								modalSpeed = opts.modalSpeed || 500,
								autoScroll = opts.autoScroll || true,
								scrollSpeed = opts.scrollSpeed || 500,
								offset = opts.offset || 75,
								$modalBg = $('<div class="zmodalBg">');
							$modalBg.css({
								'background-color': bgColor,
								'position': 'fixed',
								'z-index': '16777270',
								'top': 0,
								'left': 0,
								'width': '100%',
								'height': '100%',
								'display': 'none'
							});
							var completed = function () {
								$modal.trigger('modalShown');
								if (typeof cb === 'function') { cb.call($modal); }
							},
								doScroll = function () {
									$('html,body').animate({ scrollTop: $modal.offset().top - offset }, scrollSpeed).promise().done(function () {
										completed();
									});
								};
							return this.each(function () {
								$modalBg.appendTo('body').fadeIn(bgSpeed, function () {
									$modal.css('z-index', 16777271).fadeIn(modalSpeed, function () {
										$(document).on('keyup.modal', function (e) {
											if (e.keyCode === 27) { $modal.hideModal(); }
										});
										$modalBg.on('click.modal', function () {
											$modal.hideModal();
										});
									});
									autoScroll === true ? doScroll() : completed();
								});
							});
						},
						hideModal: function (opts, cb) {
							typeof opts === 'function' && arguments.length === 1 ? cb = opts : opts = opts || {};
							var $modal = $(this),
								$modalBg = $('.zmodalBg'),
								modalSpeed = opts.modalSpeed || 250,
								bgSpeed = opts.bgSpeed || 250,
								completed = function () {
									$modalBg.remove();
									$(document).off('keyup.modal');
									$modal.trigger('modalClosed');
									if (typeof cb === 'function') { cb.call($modal); }
								};
							$modal.fadeOut(modalSpeed, function () {
								$modalBg.fadeOut(bgSpeed, function () {
									completed();
								});
							});
							return this;
						}
					});
				},
				kThen: function () { jQuery.fn.kThen = function (time, func, selector) { var me = this, t = typeof time === 'function' ? 0 : time, f = typeof time === 'function' ? time : func; setTimeout(function () { if (typeof f === 'function') { f.apply(me); } }, t); return selector ? $(selector) : me; }; }
			}
		},
		init: function (page) {
			this.common();
			if (this.pages[page] !== '' && this.pages[page] && typeof this.pages[page] === 'function') { this.pages[page](); }
		},
		common: function () {
			
		},
		pages: {
			login: function () {
				$('.passwordRecover').on('click', function (e) {
					$(this).parents('fieldset').fadeOut('fast', function () {
						$(this).next('fieldset').fadeIn('fast');
					});
				});
			},
			createEvent: function () {
				// REPLACE - MOVE THIS TO VIEW/DATA ATTR
				// Create the ability to check if user is editing or creating and user type.
				swEvents.context = {};
				swEvents.context.editing = window.location.href.split('?')[1] ? true : false;
				swEvents.context.userType = $('#UserType').val() === 'EventManagers' ? 'Event Manager' : 'Event Creator';

				//// Managers aren't limited to number of days out
				var daysOut = swEvents.context.userType === 'Event Manager' ? 0 : 6;

				//// Update moment.js to be able to add business days for validation and minDate
				moment.fn.addWorkdays = function (days) {
					var incr = days / Math.abs(days),
						date = this.clone().add(Math.floor(Math.abs(days) / 5) * 7 * incr, 'days'),
						remaining = days % 5;
					while (remaining !== 0) {
						date.add(incr, 'days');
						if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) { remaining -= incr; }
					}
					return date;
				};

				swEvents.util.jQueryExtends.kThen();

				var panelSteps = (function () {
					var wrapper, panelSelector, next, back, panelCount, activePanel, backForward,
						stepNames = {
							1: 'Event Info',
							2: 'Location',
							3: 'Options',
							4: 'Prospects',
							5: 'Select Emails'
						},
						getPercent = function (a, b) {
							return ((a / b) / a) * 100 + '%';
						},
						sizr = function () {
							$(panelSelector).removeAttr('style').kThen(function () {
								$(panelSelector).each(function () {
									$(this).css('max-width', getPercent($(wrapper).width(), panelCount));
								});
								$(wrapper).css({
									'width': (100 * panelCount) + '%',
									'height': $(wrapper).find('.active').height(),
									'left': -($('.active').data('panel-index') * $(panelSelector).width())
								});
							});
						},
						move = { 
							forward: function (el) {
								$(el).animate({
									'left': $(el).css('left').split('px')[0] - $(panelSelector).width(),
									'height': $(el).find('.active').height()
								}, 350, function () {
									activePanel = $('.active').data('panel-index');
									backForward = 'forward';
									$('.active').trigger('activated');
									$(wrapper).trigger('switchedPane');
									panelSteps.resize();
								});
								$('html,body').animate({ scrollTop: 0 }, 'fast');
							},
							backward: function (el) {
								$(el).animate({
									'left': parseInt($(el).css('left').split('px')[0], 10) + $(panelSelector).width(),
									'height': $(el).find('.active').height()
								}, 350, function () {
									activePanel = $('.active').data('panel-index');
									backForward = 'backward';
									$('.active').trigger('activated');
									$(wrapper).trigger('switchedPane');
								});
								$('html,body').animate({ scrollTop: 0 }, 'fast');
							}
						},
						setup = function (cb) {
							panelCount = $(panelSelector).length;
							var progressItem = $('<div class="progressItem">');

							progressItem.css({
								'width': getPercent($('.progressBar').width(), panelCount)
							});

							$('#templateSubjectLine').val($('.subLines input[type=radio]:checked').val());

							$(panelSelector).each(function (k, v) {
								var $this = $(this);
								$this.attr('data-panel-index', $this.index()).find('.next, .back, [type="submit"]').each(function () {
									var $btnClicked = $(this);
									$btnClicked.on('click', function (e) {
										if (!$btnClicked.is('[type="submit"]')) { e.preventDefault(); }

										var valid = true,
											errMessage = '"*" INDICATES REQUIRED FIELDS\nPlease check the form for errors.';

										//REPLACE W REAL VALIDATION
										// Validation Check
										if ($btnClicked.is(next) || $btnClicked.is('[type="submit"]')) {
											$this.find($('[data-validate]')).each(function (k, v) {
												var myRule = $(this).attr('data-validate');

												if (!swEvents.util.validate[myRule]($(this).val(), $(this))) {
													$(this).addClass('error');
													valid = false;
												} else {
													if ($(this).is('#eventDate')) {
														var now = new Date();
														var selectedDate = moment($('#eventDate').val()),
															minDate = moment(now).addWorkdays(daysOut - 1);

														if (selectedDate <= minDate) {
															$('#eventDate').addClass('error');
															valid = false;
														} else {
															if (swEvents.util.validate[myRule]($('#eventDate').val())) {
																$('#eventDate').removeClass('error');
															}
														}
													} else {
														$(this).removeClass('error');
													}

													if ($(this).is('#zipPostal')) {
														console.log('zipPostal');
													}
												}
											});
										}
										if (valid === true) {
											if (!$btnClicked.is('[type="submit"]')) {
												$(panelSelector).not($btnClicked.parents(panelSelector)[$btnClicked.is(next) ? 'next' : 'prev']()).removeClass('active');
												$btnClicked.parents(panelSelector)[$btnClicked.is(next) ? 'next' : 'prev']().addClass('active');
												if (($btnClicked.is(back) && $btnClicked.parents(panelSelector).data('panel-index') === 0) || ($btnClicked.is(next) && $btnClicked.parents(panelSelector).data('panel-index') === $(panelSelector).length - 1)) {
													return false;
												} else {
													setTimeout(function () {
														if (!$('body').hasClass('processing')) {
															move[$btnClicked.is(next) ? 'forward' : 'backward'](wrapper);
														}
													}, 100);
													
												}
											}
										} else {
											e.preventDefault();
											if (($('.error').length > 1 && $('#eventDate').hasClass('error')) || $('#eventDate').hasClass('error')) {
												errMessage = errMessage += '\nEvent date must be at least ' + daysOut + ' business days from today.';
											} else {
												errMessage = '"*" INDICATES REQUIRED FIELDS\nPlease check the form for errors.';
											}
											alert(errMessage);
										}
									});
								});
								var item = progressItem.clone().append('<span data-steptitle="' + stepNames[++k] + '">' + ($(this).data('panel-index') + 1) + '</span>');
								item.appendTo('.progressBar');

							}).on('activated', function () {
								$('.progressItem').eq(activePanel).addClass('show').nextAll().removeClass('show');
								$('.progressItem').eq(activePanel).prev('.progressItem').addClass('completed').nextAll().removeClass('completed');
								if (activePanel === 0) {
									$('.progressItem').eq(activePanel).removeClass('completed');
								} else if (activePanel === ($('[data-panel-index]').length - 1) && $('.emailTemplates li').length > 1) {
									console.log('Last Panel');
								}
							});
							activePanel = $('.active').data('panel-index');
							$('.active').trigger('activated');
							if (typeof cb === 'function') { cb.call(); }
						};
					return {
						init: function (opts) {
							opts = opts || {};
							wrapper = opts.wrapper;
							panelSelector = opts.panelSelector;
							next = opts.nextBtn || '.next';
							back = opts.backBtn || '.back';
							setup(function () { sizr(); });
						},
						resize: function () {
							sizr();
						},
						getActivePanel: function () {
							return activePanel;
						},
						triggerMove: function (dir) {
							move[dir](wrapper);
						}
					};
				})();

				swEvents.util.actionButton.register({
					selector: '#proof, #approval'
				}).register({
					selector: '.stepThree .next.control',
					ajaxCaller: true
				});

				window.googMaps = {};

				panelSteps.init({
					wrapper: '.wizardWrap',
					panelSelector: '.wizardWrap .panel'
				});

				swEvents.util.pointsBreak.init({
					'W>0': function (W, H) {
						panelSteps.resize();
						if (googMaps.map) { googMaps.map.setCenter(googMaps.location); }
					}
				});

				$('.stepThree').on('click', '.control.next', function (e) {
					$('#noInviteSends').trigger('input');
				});

				$('.stepThree .next.control').on('processingComplete', function (e) {
					panelSteps.triggerMove('forward');
				});

				$('#sendRadiusPostalCode').on('input', function () {
					console.log('pc change', $(this).val());

					$('.stepFour').data('mapLoaded', false);

					if ($(this).val().length >= 5) {
						$('.stepFour').trigger('activated.map');
					}
				});

				$('#noInviteSends').on('input', function () {
					console.log('nosends check: ', $(this).prop('checked'));

					var srpc = document.getElementById('sendRadiusPostalCode');

					if ($(this).prop('checked')) {
						$('.stepFour').addClass('noRadius');
						$('.stepFour .send-options').hide();
						srpc.dataset.validate = 'postalNotRequired';
					} else {
						$('.stepFour').removeClass('noRadius');
						$('.stepFour .send-options').show();
						srpc.dataset.validate = 'postal';
					}

					$(window).trigger('resize');
				});

				if ($('#sendRadiusPostalCode').val() !== '') { $('#sendRadiusPostalCode').trigger('change'); }

				$('.stepFour').on('activated.map', function () {
					var $this = $(this);
					var geocoder = new google.maps.Geocoder();

					if (!$this.data('mapLoaded')) {
						var zip = $this.find('#sendRadiusPostalCode').val();

						if (zip.length >= 5) {
							geocoder.geocode({ 'address': zip }, function (results, status) {
								if (status === 'OK') {
									var markers = [];
									googMaps.location = results[0].geometry.location;
									googMaps.Latlng = new google.maps.LatLng(googMaps.location.lat(), googMaps.location.lng());
									googMaps.mapOpts = {
										scrollwheel: false,
										center: googMaps.Latlng,
										zoom: 9
									};
									window.googMaps.map = new google.maps.Map(document.getElementById('map'), googMaps.mapOpts);
									googMaps.marker = new google.maps.Marker({
										position: googMaps.Latlng,
										map: googMaps.map,
										title: $this.find('#selected-zipPostal').text(),
										animation: google.maps.Animation.DROP
									});
									$this.data('mapLoaded', true);
									googMaps.circle = new google.maps.Circle({
										map: googMaps.map,
										radius: 16093,
										strokeOpacity: 0.5,
										strokeWeight: 2,
										fillColor: '#AA0000'
									});
									googMaps.circle.bindTo('center', googMaps.marker, 'position');
								}
							});
						} else {
							var defaultZip = '66952';

							geocoder.geocode({ 'address': defaultZip }, function (results, status) {
								if (status === 'OK') {
									var markers = [];
									googMaps.location = results[0].geometry.location;
									googMaps.Latlng = new google.maps.LatLng(googMaps.location.lat(), googMaps.location.lng());
									googMaps.mapOpts = {
										scrollwheel: false,
										center: googMaps.Latlng,
										zoom: 3
									};
									window.googMaps.map = new google.maps.Map(document.getElementById('map'), googMaps.mapOpts);

									$this.data('mapLoaded', true);
								}
							});
						}
					}
				});

				// create event map radius selector
				const prospectMap = document.getElementsByClassName('prospect-map')[0];

				if (prospectMap) {
					const radiusSwapper = {
						options: [].slice.call(prospectMap.getElementsByClassName('prospect-map__input')),
						calcButton: document.getElementsByClassName('prospect-map__btn')[0],
						copyContainer: document.getElementsByClassName('prospect-map__result')[0],
						estimatedSendsCopy: document.getElementsByClassName('prospect-map__estimate')[0],
						milesRadiusCopy: document.getElementsByClassName('prospect-map__miles')[0],
						updateCopy: (sends, miles) => {
							radiusSwapper.estimatedSendsCopy.textContent = sends;
							radiusSwapper.milesRadiusCopy.textContent = miles;
						},
						savedData: {
							10: {
								totalSends: null,
							},
							25: {
								totalSends: null,
							},
							50: {
								totalSends: null,
							},
							75: {
								totalSends: null,
							},
							100: {
								totalSends: null,
							}
						},
						activeMileSelection: 10
					};

					// update the value of activeMileSelection whenever the user selects an option
					radiusSwapper.options.forEach(item => {
						item.addEventListener('click', event => {
							if (event.target.classList.contains('prospect-map__input')) {
								const inputValue = event.target.value;

								radiusSwapper.activeMileSelection = inputValue;
							}
						});
					});

					// logic for the update estimate button
					radiusSwapper.calcButton.addEventListener('click', event => {
						event.preventDefault();

						// sets the zoom level on the map
						googMaps.circle.setOptions({
							radius: swEvents.util.gMapsHelpers.convertToMeters(radiusSwapper.activeMileSelection)
						});

						var postalCode = document.getElementById('zipPostal').value;
						var sendRadiusPostalCode = document.getElementById('sendRadiusPostalCode').value;

						postalCode = sendRadiusPostalCode.length === 0 ? postalCode : sendRadiusPostalCode;

						const radius = parseInt(radiusSwapper.activeMileSelection, 10);

						//remove spaces from CAN postal code
						postalCode = postalCode.replace(/\s+/g, '');

						googMaps.map.setZoom(radius === 10 ? 9 : (radius === 25 ? 8 : (radius === 50 ? 7 : (radius === 75 ? 6 : 5))));

						// if there are no total sends for the radius selected, make an ajax request for the data
						if (radiusSwapper.savedData[radiusSwapper.activeMileSelection].totalSends === null) {

							// disable the submit button before the ajax request is sent
							radiusSwapper.calcButton.disabled = true;

							const currentRadiusSelected = radiusSwapper.activeMileSelection;

							radiusSwapper.updateCopy("?", currentRadiusSelected);

							var data = { 'radius': currentRadiusSelected, 'postalcode': postalCode };

							$.post(estimateinvitesendsurl, data, function (response) {

								console.log(response);

								// assign the retrieved estimated sends data to a variable
								const retrievedEstimateSends = response; // ex. data.totalSends

								// add that sends data to the savedData object
								radiusSwapper.savedData[radiusSwapper.activeMileSelection].totalSends = retrievedEstimateSends;

								// update the results copy
								radiusSwapper.updateCopy(retrievedEstimateSends, currentRadiusSelected);

								// if the result copy isn't already visible on the page, show it after we get the json data
								if (radiusSwapper.copyContainer.classList.contains('prospect-map__result--show') === false) {
									radiusSwapper.copyContainer.classList.add('prospect-map__result--show');
								}

								// enable the submit button
								radiusSwapper.calcButton.disabled = false;
							});
						} else {
							// if we already have data for the estimated sends for a radius, just use what we have in the savedData object
							radiusSwapper.updateCopy(radiusSwapper.savedData[radiusSwapper.activeMileSelection].totalSends, radiusSwapper.activeMileSelection);
						}
					});
				}
			},
			eventsAdmin: function () {
				swEvents.util.initDeleteEventButton();
				swEvents.util.tableSortr.init({
					tableSelector: 'table'
				});

				console.log('eventsAdmin');

				$('[data-tooltip="Request Approval"]').on('click', function () {
					console.log("Requesting Approval");

					var $this = $(this),
						myId = $this.parents('tr').data('eventid'),
						data = { eid: myId };

					data = JSON.stringify(data);

					if (confirm('Are you sure you would like to submit this event for approval?')) {
						$.ajax({
							url: submitforapprovalurl + '?eid=' + myId,
							type: 'POST',
							contentType: "application/json; charset=utf-8",
							data: data,
							success: function (result) {
								//console.log('result', result);

								if (result.IsError !== false) {
									$this.off('click');

									$this.parents('tr').find('.notSumbittedMsg').remove();

									$this.remove();
								} else {
									alert('There was an error\n' + result.responseJSON.Message);
								}
							}
						});
					}
				});

				var cancellationNotice = document.getElementsByClassName('cancellation-notice')[0];
				if (cancellationNotice) {
					cancellationNotice.addEventListener('click', function () {
						this.classList.remove('cancellation-notice--show');
					});
				}

			},
			eventCreator: function () {
				Modernizr.load([{
					load: '/js/jquery.peity.min.js',
					complete: function () {
						swEvents.util.initDeleteEventButton();
						swEvents.util.jQueryExtends.modals();

						swEvents.util.tableSortr.init({
							tableSelector: 'table',
							ignoreHeading: 'thead tr th:last-child'
						});

						$('img').on('load', function () {
							var $this = $(this);
							if ($this.prop('src').split('.')[1] !== 'gif') {
								$this.addClass('loaded').off('load');
							}
						});

						$('.toggleGraph').on('click', function (e) {
							e.preventDefault();
							var $this = $(this);
							if (!$('.graphPlaceholder + .peity').length) {
								swEvents.util.loadChart({
									el: '.graphPlaceholder',
									nums: [
										$('.graph').parent('.event').data('event-attended'),
										$('.graph').parent('.event').data('event-sent')
									],
									width: 200,
									height: 200,
									innerRadius: 75
								});
							}
							$('.graph').fadeToggle('fast');
							$this.text($this.text() === 'Show Graph' ? 'Hide Graph' : 'Show Graph');
							if ($this.text() === 'Hide Graph') {
								$('html,body').animate({ scrollTop: $('.graph').offset().top - 200 }, 'fast');
							}
						});

						$('.eventSummary .event').each(function () {
							var $this = $(this);
							var mapSrc = swEvents.util.gMapsHelpers.getMapImgUrl({
								addr: $this.find('address').text(),
								zoom: '14',
								size: '300x300',
								color: 'blue'
							});

							$this.find('.map').prop('src', mapSrc).on('click', function () {
								if (!$('.modal img').length) {
									var img = $('<img class="map">').attr('src', swEvents.util.gMapsHelpers.getMapImgUrl({
										addr: $(this).parents('.event').find('address').text(),
										zoom: '17',
										size: swEvents.util.pointsBreak.getWidth() <= 500 ? '400x400' : '600x600',
										color: 'blue'
									}));
									$('.modal .modalInner').append(img);
								}
								$('.modal').showModal().on('modalClosed', function () {
									$(this).off('modalClosed').find('img').remove();
								}).find('.closeBtn').on('click', function (e) {
									e.preventDefault();
									$(this).off('click');
									$('.modal').hideModal();
								});
							});
						});
					}
				}]);
			},
			eventDetails: function () {
				Modernizr.load([{
					complete: function () {

						swEvents.util.jQueryExtends.modals();

						if ($('[data-print-style]').length) {
							$('head').append('<link rel="stylesheet" media="print" type="text/css" href="' + $('[data-print-style]').data('print-style') + '">');
						}

						$('.icon-printer').on('click', function () {
							$('.printDetails').show();
						});

						$('.printDetails__print-button').on('click', function () {
							var header = $('.mainHeader').html(),
								body = $('.mainContent').html(),
								styles = $('link'),
								printWindow = window.open("", "", "");

							printWindow.document.write('<html><head>');
							styles.each(function (i) {
								printWindow.document.write('<link rel="stylesheet" type="text/css" href="', styles[i].href, '" />');
							});
							printWindow.document.write('<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js"></script>');
							printWindow.document.write('<script type="text/javascript">$(window).load(function(){window.print();});</script>');
							printWindow.document.write('</head><body class="eventDetails dashboard loaded"><header class="mainHeader">');
							printWindow.document.write(header);
							if ($('.presenterDetails').length <= 0) {
								printWindow.document.write('</header><div class="mainContent noPresenter">');
							}
							else {
								printWindow.document.write('</header><div class="mainContent">');
							}
							printWindow.document.write(body);
							printWindow.document.write('</div></body></html>');
							printWindow.document.close();
							printWindow.focus();
						});
						var mapSrc = swEvents.util.gMapsHelpers.getMapImgUrl({
							addr: $('.venueMeta').find('address').text(),
							zoom: '12',
							size: '480x480',
							color: 'blue'
						});

						$('.venueMeta').find('.map').prop('src', mapSrc).on('click', function () {
							if (!$('.modal img').length) {
								var img = $('<img class="map">').attr('src', swEvents.util.gMapsHelpers.getMapImgUrl({
									addr: $(this).parents('.venueMeta').find('address').text(),
									zoom: swEvents.util.pointsBreak.getWidth() <= 500 ? '16' : '17',
									size: swEvents.util.pointsBreak.getWidth() <= 500 ? '420x420' : '720x720',
									color: 'blue'
								}));
								$('.modal .modalInner').append(img);
							}
							$('.modal').showModal().on('modalClosed', function () {
								$(this).off('modalClosed').find('img').remove();
							}).find('.closeBtn').on('click', function (e) {
								e.preventDefault();
								$(this).off('click');
								$('.modal').hideModal();
							});
						});
						
						if (document.getElementsByClassName('cancel-prompt')[0]) {
							var cancelPrompt = {
								eventName: $('.eventName').text(),
								postURL: $('button[data-action="cancel-event"]').attr('href'),
								textInput: $('#confirmation'),
								submitBtn: $('a[data-action="cancel-event"]'),
								exitBtn: $('button[data-action="exit-prompt"]'),
								reset: function () {
									this.textInput[0].value = '';
									this.submitBtn.removeClass('btn--red');
								}
							};


							$(cancelPrompt.submitBtn).on('click', function (e){
								cancelPrompt.submitBtn.replaceWith('<a href="#" class="btn btn--red" data-action="cancel-event">Cancelling... <span class="icon icon-trash"></span></a>');
							});

							$('.cancel-prompt__eventName').text(cancelPrompt.eventName);

							cancelPrompt.textInput.on('keyup', function (e) {
								if (this.value === 'CONFIRM') {
									cancelPrompt.submitBtn.addClass('btn--red')
								} else if (cancelPrompt.submitBtn.hasClass('btn--red')) {
									cancelPrompt.submitBtn.removeClass('btn--red');
								}
							});

							$('a[data-context="cancel-event"]').on('click', function (e) {
								e.preventDefault();
								
								$('.cancel-prompt').showModal().on('modalClosed', function () {
									cancelPrompt.reset();
								}).find('.closeBtn').on('click', function (e) {
									e.preventDefault();
									$(this).off('click');
									$('.cancel-prompt').hideModal();
									cancelPrompt.reset();
								});
							});

							cancelPrompt.exitBtn.on('click', function (e) {
								e.preventDefault();
								$('.cancel-prompt').hideModal();
								cancelPrompt.reset();
							});
						}
						
						$('.eventDetails .selectedTemplate .btn.icon-envelop').on('click', function (e) {
							e.preventDefault();
							var myId = $(this).data('eid');
							$.ajax({
								url: sendproofurl + '?eid=' + myId,
								method: 'POST',
								complete: function (data) {
									console.log(data);
									//data = JSON.parse(data);
									if (data.IsError !== false) {
										$('.eventDetails .selectedTemplate .btn.icon-envelop').text('Proof Sent');
									} else {
										alert('There was an error\n' + data.responseJSON.Message);
									}
								}
							});
						});
					}
				}]);
			}
		}
	};

	$(function () { swEvents.init($('html').data('page')); });
