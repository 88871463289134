import flatpickr from 'flatpickr';
import * as FilePond from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

(function () {
	let form = document.querySelector('.eventCreateForm');

	if (form == undefined) { return }

	function customSubjectLine() {
		//page load, set default values
		$('.emailTemplates input[name=SelectedEmailTemplate]:checked').each(function () {
			$(this).parents('li').addClass('selected');
		});

		if ($('.emailTemplates input[name=SelectedEmailTemplate]:checked').length) {
			if ($('.emailTemplates input[name=SelectedEmailTemplate]:checked').data('imageupload').toLowerCase() === 'true') {
				$('.template-image').css("display", "block");
			}
		}

		$('.emailTemplates li').not('.selected').find('.subLines input[type=radio]').each(function () {
			$(this).attr('disabled', 'disabled');
		});

		//user interacts with form
		$('input[name=SelectedEmailTemplate]').on('click', function () {
			var templateImageUpload = $('.template-image');

			$('ul.emailTemplates li').removeClass('selected');
			$(this).parents('li').addClass('selected');

			$('.emailTemplates li').not('.selected').find('.subLines input[type=radio]').each(function () {
				$(this).prop('disabled', true);
				$(this).prop('checked', false);
			});

			$('.emailTemplates li.selected').find('.subLines input[type=radio]').each(function () {
				$(this).prop('disabled', false);
			});

			$('.emailTemplates li.selected').find('.subLines input[type=radio]').first().prop('checked', true).trigger('change');

			if (($(this).data("imageupload").toLowerCase() === 'true')) {
				$(templateImageUpload).show();
			}
			else {
				$(templateImageUpload).hide();
			}

			$(window).trigger('resize');
		});
	}

	function setupDateTimePickers() {
		var eventDate = document.querySelector('#eventDate');
		var eventTime = document.querySelector('#eventTime');
		var startTime = document.querySelector('#startTime');

		flatpickr(eventDate, {
			defaultDate: null,
			minDate: eventDate.dataset.mindate,
			dateFormat: 'Y-m-d',
			onReady: function (selectedDates, dateStr, instance) {
				if (dateStr == null) instance.clear(); //prevent auto-fill on new event
			}
		});

		flatpickr(eventTime, {
			enableTime: true,
			noCalendar: true,
			dateFormat: 'h:iK',
		});

		flatpickr(startTime, {
			enableTime: true,
			noCalendar: true,
			dateFormat: 'h:iK',
		});
	}

	function fileUploaders() {
		FilePond.registerPlugin(
			// validates the size of the file
			FilePondPluginFileValidateSize,
			// previews dropped images
			FilePondPluginImagePreview
		);

		// Get a reference to the file input elements
		const presenterEl = form.querySelector('input[type="file"].presenter');
		const sponsorEl = form.querySelector('input[type="file"].sponsor');
		const templateEl = form.querySelector('input[type="file"].template');

		const presenterPhotosInput = document.querySelector('input[name="PresenterPhotosFilePond"]');
		const sponsorPhotosInput = document.querySelector('input[name="SponsorPhotosFilePond"]');
		const templatePhotoInput = document.querySelector('input[name="TemplateImageCustomizationFilePond"]');

		initFilePond(presenterEl, presenterPhotosInput);
		initFilePond(sponsorEl, sponsorPhotosInput);
		initFilePond(templateEl, templatePhotoInput);

		// Create FilePond instances
		function initFilePond(el, field) {
			var ups = [];
			var existing = field.value.length > 0 ? JSON.parse(field.value) : null;
			var loadExisting = [];
			var wrapper = $('.wizardWrap.eventCreateForm');

			if (existing) {
				for (var i = 0; i < existing.length; i++) {
					let o = {};
					let img = existing[i];

					o.source = img.FileName;
					o.filetype = img.FileType;
					o.options = { type: 'local' };

					loadExisting.push(o);
				}
			}

			let pond = FilePond.create(el, {
				server: {
					process: (fieldName, file, metadata, load, error, progress, abort) => {
						const formData = new FormData();
						formData.append(fieldName, file, file.name);
						formData.append('EventId', eventId);
						formData.append('FieldName', field.name);
						formData.append('FieldAlias', field.dataset.fieldalias);

						const request = new XMLHttpRequest();
						request.open('POST', imageposturl);
						// Setting computable to false switches the loading indicator to infinite mode
						request.upload.onprogress = (e) => {
							progress(e.lengthComputable, e.loaded, e.total);
						};

						request.onload = function () {
							if (request.status >= 200 && request.status < 300) {
								load(request.responseText);// the load method accepts either a string (id) or an object

								//update hidden field with new list IF new event (event edit gets updated in API endpoint)
								if (eventId == "0") {
									ups.push(JSON.parse(request.responseText));
									field.value = JSON.stringify(ups);
								}
								$(wrapper).height($(wrapper).find('.active').height());
							}
							else {
								error('Error during Upload!');
							}
						};

						request.send(formData);
						//expose an abort method so the request can be cancelled
						return {
							abort: () => {
								// This function is entered if the user has tapped the cancel button
								request.abort();
								// Let FilePond know the request has been cancelled
								abort();
							}
						};
					},
					remove: (source, load, error) => {
						//actually delete the file
						let req = {
							'fieldname': field.name,
							'eventid': eventId,
							'filename': source,
							'fieldalias': field.dataset.fieldalias
						};

						const request = new XMLHttpRequest();
						request.open('DELETE', imageremoveurl);
						
						request.onload = function () {
							if (request.status >= 200 && request.status < 300) {
								load();
							}
							else {
								error('Error while removing file!');
							}
						}

						//we're sending the metadata over, the endpoint will update the field during request
						request.send(JSON.stringify(req));
					},
					revert: (source, load, error) => {
						const request = new XMLHttpRequest();
						var data = JSON.parse(source);

						data.FieldName = field.name;
						data.eventid = eventId;

						request.open('DELETE', imagereverturl);

						request.onload = function () {
							if (request.status >= 200 && request.status < 300) {
								load();

								if (eventId == "0") {
									var existing = JSON.parse(field.value);

									ups = existing.filter(function (obj) {
										obj = JSON.parse(obj);
										return obj.Guid !== source.Guid;
									});

									//update hidden field with new list IF new event (event edit gets updated in API endpoint)
									field.value = JSON.stringify(ups);
								}								
							}
							else {
								error('Error while reverting file!');
							}
						}
						
						request.send(JSON.stringify(data));
					},
					load: imageloadurl + '?guid=',
				},
				files: loadExisting
			});
		}		
	}

	customSubjectLine();
	setupDateTimePickers();
	fileUploaders();
}());