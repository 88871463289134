//on page load, call function
$(document).ready(function () {
	if ($('.landingPage').length) {
		registrationPage();
		$('body').addClass('loaded');
	}
});

function registrationPage() {
	var map,
		markers = [],
		eventLoc = {};

	swEventsUtilGMapsHelpersGetLocationLatLong($('.eventInfo address').text(), function (result) {
		eventLoc.lat = result.lat;
		eventLoc.lng = result.lng;

		map = new google.maps.Map(document.getElementById('map'), {
			center: {
				lat: eventLoc.lat,
				lng: eventLoc.lng
			},
			zoom: 15,
			scrollwheel: false
		});
		swEventsUtilGMapsHelpersCreateMarker({
			markers: markers,
			map: map,
			markerCount: 0,
			lat: eventLoc.lat,
			lng: eventLoc.lng
		});
		swEventsUtilPointsBreakInit({
			'W>0': function () { map.setCenter({ lat: eventLoc.lat, lng: eventLoc.lng }); }
		});
	});

	$('.register').on('blur', 'input[name="Email"]', function () {
		var Email = $(this).val(),
			EventID = $(this).parents('fieldset').attr('data-eid');

		var data = {
			EventID: EventID,
			Email: Email
		};

		data = JSON.stringify(data);

		console.log(data);
		if (Email) {
			$.ajax({
				type: 'POST',
				contentType: "application/json; charset=utf-8",
				url: CheckRegistrantEmail,
				data: data,
				success: function (result) {
					//TODO
					//based on the results, enable/disable the form submit button; display relevant message
					//TODO
					console.log(result);
				}
			});
		}
	});

	$('#privacyCheck').on('change', function (e) {
		var state = $(this).prop('checked');
		var regButton = $('#registerButton');
		if (state) {
			regButton.attr('disabled', false);
		}
		else {
			regButton.attr('disabled', true);
		}
	})
};

function swEventsUtilGMapsHelpersGetLocationLatLong(address, cb) {
	var latlong;
	jQuery.support.cors = true;
	var geocoder = new google.maps.Geocoder();

	geocoder.geocode({ 'address': address }, function (results, status) {
		if (status === 'OK') {
			latlong = {
				lat: parseFloat(results[0].geometry.location.lat()),
				lng: parseFloat(results[0].geometry.location.lng())
			};

			cb(latlong);
		}
		else {
			console.log("GMaps Geocode ERROR");
		}
	});
}

function swEventsUtilGMapsHelpersCreateMarker(opts) {
	swEventsUtilJQueryExtendsKThen();
	opts.markers[opts.markerCount] = new google.maps.Marker({
		position: {
			lat: parseFloat(opts.lat),
			lng: parseFloat(opts.lng)
		},
		map: opts.map,
		title: opts.eventName
	});
	if (opts.showInfo === true) {
		opts.markers[opts.markerCount].addListener('click', function () {
			$('html,body').animate({ scrollTop: $('.map').offset().top - 50 }, 200, function () {
				opts.map.setZoom(8);
				opts.map.setCenter(opts.markers[opts.markerCount].getPosition());
				opts.info.open(opts.map, opts.markers[opts.markerCount]);
			});
		});
	} else if (opts.showInfo === false && typeof opts.customEvent === 'function') {
		opts.markers[opts.markerCount].addListener('click', function () {
			opts.map.setZoom(8);
			opts.map.setCenter(opts.markers[opts.markerCount].getPosition());
			opts.customEvent(opts.markers[opts.markerCount]);
		});
	}
}

function swEventsUtilJQueryExtendsKThen() {
	jQuery.fn.kThen = function (time, func, selector) {
		var me = this, t = typeof time === 'function' ? 0 : time,
			f = typeof time === 'function' ? time : func; setTimeout(function () { if (typeof f === 'function') { f.apply(me); } }, t); return selector ? $(selector) : me;
	};
}

function swEventsUtilPointsBreakInit(context) {
	var doIt;
	doWork(context);
	window.onresize = function () {
		clearTimeout(doIt);
		doIt = setTimeout(function () { doWork(context); }, time);
	};
	return this;
}

function doWork(context) {
	var time = 200;
	var W, H;
	var andReg = /&&+/g;
	W = window.innerWidth || document.documentElement.clientWidth;
	H = window.innerHeight || document.documentElement.clientHeight;
	for (var i in context) {
		if (i.charAt(0) === 'W' || i.charAt(0) === 'H') {
			i = i.replace(/\s+/g, '').toUpperCase();
			var f = 0,
				myFunc = context[i];
			if (i.match(andReg) !== null) {
				var tests = i.split(/&&/);
				for (var x in tests) {
					if (tests[x].charAt(0) === 'W' || tests[x].charAt(0) === 'H') {
						if (breakPointTest(tests[x], W, H) === false) { f = false; }
					}
				}
				if (f !== false) { runThis(myFunc(W, H)); }
			} else {
				if (breakPointTest(i, W, H)) { runThis(myFunc(W, H)); }
			}
		}
	}
}

function breakPointTest(bpTest, W, H) {
	var type = bpTest.charAt(0) === 'W' ? W : H,
		test = bpTest[1],
		dims = bpTest.split(bpTest.charAt(1))[1];
	return test === '>' && type > dims || test === '<' && type < dims ? true : false;
}

function runThis(fin) {
	if (typeof fin === 'function') { fin(); }
}