//on page load, call function
$(document).ready(function () {
	if ($('.checkin').length) {
		checkin();
		$('body').addClass('loaded');
	}
});

function checkin() {
	swEventsUtilTableSortrInit({
		tableSelector: 'table',
		ignoreHeading: 'thead th:nth-child(3), thead tr th:last-child'
	});

	$('#addNewQuick').on('click', function (e) {
		e.preventDefault();
		showModal($('.modal.quickReg'));

		resetForm($('.modal.quickReg form.register'));
		$('.register').find('button.btn').html('Register <span class="icon icon-plus"></span>');
		$("#UpdateReg").val(false);
	});

	$('.modal.quickReg').on('modalShown', function () {
		$('.closeBtn').on('click.modal', function () {
			hideModal($('.modal.quickReg'));
		});
	}).on('modalClosed', function () {
		resetForm($('.modal.fullReg form.register'));
		$('.closeBtn').off('click.modal');
	});

	$('#addNewFull').on('click', function (e) {
		e.preventDefault();
		$('.modal.fullReg').removeClass('updateCEC');
		showModal($('.modal.fullReg'));

		resetForm($('#register'));
		$('.register').find('button.btn').html('Register <span class="icon icon-plus"></span>');
		$("#UpdateReg").val(false);
	});

	$('.modal.fullReg').on('modalShown', function () {
		$('.closeBtn').on('click.modal', function () {
			hideModal($('.modal.fullReg'));
		});
	}).on('modalClosed', function () {
		resetForm($('.modal.fullReg form.register'));
		$('.closeBtn').off('click.modal');
	});

	$('#search').on('keyup', function () {
		var theSearchVal = $(this).val().toLowerCase();
		if (theSearchVal === '') {
			$('tbody tr').show();
		} else {
			$('tbody tr').each(function () {
				$(this).text().toLowerCase().indexOf(theSearchVal) >= 0 ? $(this).show() : $(this).hide();
			});
		}
	});

	$(window).on('listLoading', function () {
		$('body').addClass('listLoading').append('<img id="listLoder" src="/img/clearGif.gif" alt="Loading..." >');
	}).on('listDoneLoading', function () {
		$('body').removeClass('listLoading').find('#listLoder').remove();
	});

	$('.registrants').on('change', 'tr td:last-child input[type="checkbox"]', function () {
		$(this).parents('tr').trigger($(this).is(':checked') ? 'checkin' : 'notcheckedin');
	});

	$('.registrants tbody').on('click', 'tr .update', function (e) {
		e.preventDefault();

		resetForm($('.modal.fullReg form.register'));

		$('.modal.fullReg form.register #Email').attr('readonly', 'readonly');

		var email = $(this).parents('tr').attr('data-email'),
			eid = $(this).parents('tr').attr('data-eventid'),
			regId = $(this).parents('tr').attr('data-regid');

		var data = {
			EventID: eid,
			Email: email,
			RegID: regId
		};

		data = JSON.stringify(data);

		$.ajax({
			type: 'POST',
			contentType: "application/json; charset=utf-8",
			url: getRegistrantInfoUrl,
			data: data,
			success: function (result) {
				updateFormFields(result, $('.register'));
				showModal($('.modal.fullReg'));
				$('.register').find('button.btn').html('Update <span class="icon icon-plus"></span>');
				$("#UpdateReg").val(true);
			}
		});
	});

	$('.registrants tbody').on('click', '.updateCECNum', function (e) {
		e.preventDefault();

		resetForm($('.modal.updateCEC form.register'));

		var email = $(this).parents('tr').attr('data-email'),
			eid = $(this).parents('tr').attr('data-eventid'),
			regId = $(this).parents('tr').attr('data-regid'),
			data = {
				EventID: eid,
				Email: email,
				RegID: regId
			};

		data = JSON.stringify(data);

		$.ajax({
			type: 'POST',
			contentType: "application/json; charset=utf-8",
			url: getRegistrantInfoUrl,
			data: data,
			success: function (result) {
				updateFormFields(result, $('.register'));
				//$('.modal.updateCEC').addClass('updateCEC');
				showModal($('.modal.updateCEC'));
				$('.register').find('button.btn').html('Update <span class="icon icon-plus"></span>');
				$("#UpdateReg").val(true);
			}
		});
	});

	$('.modal.updateCEC').on('modalShown', function () {
		$('.closeBtn').on('click.modal', function () {
			hideModal($('.modal.updateCEC'));
		});
	}).on('modalClosed', function () {
		resetForm($('.modal.updateCEC form.register'));
		$('.closeBtn').off('click.modal');
	});

	$('.register').on('blur', 'input[name="Email"]', function () {
		var Email = $(this).val(),
			EventID = $(this).parents('fieldset').attr('data-eid');

		var data = {
			EventID: EventID,
			Email: Email
		};

		data = JSON.stringify(data);

		console.log(data);
		if (Email) {
			$.ajax({
				type: 'POST',
				contentType: "application/json; charset=utf-8",
				url: CheckRegistrantEmail,
				data: data,
				success: function (result) {
					//TODO
					//based on the results, enable/disable the form submit button; display relevant message
					//TODO
					console.log(result);
				}
			});
		}
	});

	$('.registrants').on('checkin', 'tr', function () {
		var data = {
			RegID: $(this).attr('data-regid'),
			EventID: $(this).attr('data-eventid'),
			Email: $(this).attr('data-email'),
			ContactMe: String($(this).find('input.contact').eq(0).prop('checked'))
		};

		data = JSON.stringify(data);
		var el = $(this);
		$.ajax({
			type: 'POST',
			contentType: "application/json; charset=utf-8",
			url: checkInPost,
			data: data,
			success: function (result) {
				if (result.checkin !== false) {
					//swEvents.pages.checkin.checkedinCounter++;
					$(el).addClass('checkedin');

					/*if (swEvents.pages.checkin.createdCounter && swEvents.pages.checkin.checkedinCounter && swEvents.pages.checkin.createdCounter === swEvents.pages.checkin.checkedinCounter) {
						$(window).trigger('listDoneLoading');
					}*/
				} else {
					$(el).find('input:checkbox').removeAttr('checked');
					alert("The check in attempt was unsuccessful. Please try again.");
				}
			},
			error: function (result) {
				$(el).find('input:checkbox').removeAttr('checked');
				alert("The check in attempt was unsuccessful. Please try again.");
			}
		});
	}).on('notcheckedin', 'tr', function () {

		var data = {
			RegID: $(this).attr('data-regid'),
			EventID: $(this).attr('data-eventid'),
			Email: $(this).attr('data-email')
		};

		data = JSON.stringify(data);

		var el = $(this);

		$.ajax({
			type: 'POST',
			contentType: "application/json; charset=utf-8",
			url: checkOutPost,
			data: data,
			success: function (result) {
				$(el).removeClass('checkedin');
			}
		});
	});
}

function updateFormFields(result, form) {

	var fields = $(form).find('input, select');

	$.each($.parseJSON(result), function (index, jsonObject) {
		$.each(jsonObject, function (key, value) {

			var el = $(form).find('#' + key);

			if ($(el).is('select')) {
				$(el).find('option').filter(function () {
					return $(this).val() === value;
				}).prop('selected', true);
			} else if ($(el).is('input:checkbox')) {
				if (value === true) {
					$(el).prop('checked', true);
				} else {
					$(el).prop('checked', false);
				}
			}
			else {
				$(el).attr('value', value);
			}
		});
	});
}

function resetForm(form) {
	form.find('input:text, input:password, input:file, select, textarea').attr('value', '');
	form.find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected');
	form.find('input:text, input:password, input:file, select, textarea').removeAttr('disabled');
}

function showModal(el, opts, cb) {
	typeof opts === 'function' && arguments.length === 1 ? cb = opts : opts = opts || {};
	var $modal = el,
		bgColor = opts.bgColor || 'rgba(0,0,0,.7)',
		bgSpeed = opts.bgSpeed || 500,
		modalSpeed = opts.modalSpeed || 500,
		autoScroll = opts.autoScroll || true,
		scrollSpeed = opts.scrollSpeed || 500,
		offset = opts.offset || 75,
		$modalBg = $('<div class="zmodalBg">');
	$modalBg.css({
		'background-color': bgColor,
		'position': 'fixed',
		'z-index': '16777270',
		'top': 0,
		'left': 0,
		'width': '100%',
		'height': '100%',
		'display': 'none'
	});

	$modalBg.appendTo('body').fadeIn(bgSpeed, function () {
		$modal.css('z-index', 16777271).fadeIn(modalSpeed, function () {
			$(document).on('keyup.modal', function (e) {
				if (e.keyCode === 27) { hideModal($modal); }
			});
			$modalBg.on('click.modal', function () {
				hideModal($modal);
			});
		});
		autoScroll === true ? doScroll($modal, scrollSpeed, offset) : completed($modal);
	});
}

function completed($modal) {
	$modal.trigger('modalShown');
	if (typeof cb === 'function') { cb.call($modal); }
}

function doScroll($modal, scrollSpeed, offset) {
	$('html,body').animate({ scrollTop: $modal.offset().top - offset }, scrollSpeed).promise().done(function () {
		completed($modal);
	});
}

function hideModal(el, opts, cb) {
	typeof opts === 'function' && arguments.length === 1 ? cb = opts : opts = opts || {};
	var $modal = el,
		$modalBg = $('.zmodalBg'),
		modalSpeed = opts.modalSpeed || 250,
		bgSpeed = opts.bgSpeed || 250;
		
	$modal.fadeOut(modalSpeed, function () {
		$modalBg.fadeOut(bgSpeed, function () {
			hideModalCompleted($modalBg, $modal, cb);
		});
	});
	return this;
}

function hideModalCompleted($modalBg, $modal, cb) {
	$modalBg.remove();
	$(document).off('keyup.modal');
	$modal.trigger('modalClosed');
	if (typeof cb === 'function') { cb.call($modal); }
}

function swEventsUtilTableSortrInit(opts) {
	var theTable, ignoreHeading, useUTC;

	opts = opts || {};
	theTable = opts.tableSelector || 'table.sortable';
	ignoreHeading = opts.ignoreHeading || '.sortIgnore';
	swEventsUtilTableSortrInitSetup(theTable, ignoreHeading, useUTC);
}

function swEventsUtilTableSortrInitSetup(theTable, ignoreHeading, useUTC) {
	$(theTable).each(function () {
		$(this).find('thead th').not(ignoreHeading).on('click', function () {
			var $this = $(this),
				colIdx = $this.index(),
				toSort = [],
				curTblBod = $this.parents('table').find('tbody'),
				newTblBod = $('<tbody>');

			if ($(this).attr('data-utc')) {
				useUTC = true;
			}

			$this.toggleClass('toggle');

			curTblBod.find('tr').each(function (k, v) {
				if (useUTC === true) {
					toSort.push([$(this).children('td').eq(colIdx).attr('data-utc'), $(this).index()]);
				} else {
					toSort.push([$(this).children('td').eq(colIdx).text(), $(this).index()]);
				}
			});

			toSort.sort();

			if ($(theTable).data('sorted') === 'descending') {
				toSort.reverse();
				$(theTable).data('sorted', 'ascending');
			} else {
				$(theTable).data('sorted', 'descending');
			}

			$.each(toSort, function (k, v) {
				curTblBod.find('tr').eq(v[1]).clone(true).appendTo(newTblBod);
			});
			curTblBod.replaceWith(newTblBod);
		});
	});
}