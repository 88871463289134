//entrypoint for Webpack

//css
import './main.scss';

//js
//import '../js/browserFixes.js';
//import '../js/jquery.bifrost.min.js';
//import '../js/jquery.peity.min.js';
//import '../js/jquery.placeholder.min.js';
import '../js/jquery.ui.widget.js';
//import '../js/jquery-1.11.3.min.js';
//import '../js/jquery-2.1.4.min.js';
import '../js/jquery-ui.min.js';
//import '../js/jquery-ui.timepicker.datepicker.slider.min.js';
//import '../js/jquery-ui-timepicker-addon.js';
//import '../js/respond.min.js';
//import '../js/selectivizr-min.js';
import './main.js';

// NEW
import './cancel-event/cancel-event';
import './event-listings/event-listings';
import './event-details/event-details';
import './event-create-form/event-create-form';
import './home-page/home-page';
import './registration-page/registration-page';
import './check-in-page/check-in-page';
