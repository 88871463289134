//on page load, call function
$(document).ready(function () {
	if ($('.masterEventListing').length) {
		eventListing();
		$('body').addClass('loaded');
	}
});

function eventListing() {
	var map,
		markers = [];

	swEventsUtilPointsBreakInit();
	swEventsUtilJQueryExtendsKThen();
	map = new google.maps.Map(document.getElementById('map'), {
		center: { lat: 39.5, lng: -98.35 },
		zoom: 1,
		scrollwheel: false,
		mapTypeControl: false
	});

	$('.map').addClass('notLoaded');

	swEventsUtilPointsBreakInit({
		'W>0': function () { map.setCenter({ lat: 39.5, lng: -98.35 }); },
		'W<601': function () { map.setZoom(2); },
		'W>601&&W<900': function () { map.setZoom(3); },
		'W>900': function () { map.setZoom(4); }
	});

	swEventsUtilTableSortrInit({
		tableSelector: 'table',
		ignoreHeading: 'table th:last-child, table th:nth-child(2)'
	});

	var eventListMap = {
		dataArr: [],
		eventsSort: {},
		eventsClosestToUser: null,
		mapBoundsClosestToUser: null,
		mapBoundsAllMarkers: new google.maps.LatLngBounds(),
		sortedEventsPanel: document.getElementsByClassName('map-listing__list--sorted')[0],
		detailedEventsPanel: document.getElementsByClassName('map-listing__detail')[0],
		closeBtn: document.getElementsByClassName('map-listing__closeBtn')[0],
		geoLocaterBtn: document.getElementsByClassName('map-listing__goLocBtn')[0],
		loadingOverlay: document.getElementsByClassName('events-map__overlay')[0],
		errorModal: document.getElementsByClassName('events-map__errorModal')[0],
		mapListingNav: document.getElementsByClassName('map-listing__nav')[0],
		mapListingWrapper: document.getElementsByClassName('map-listing__listWrapper')[0],
		dictionary: {
			noLocationsFound: 'Unable to find events near your location',
			permissionIssue: 'Geolocation settings are disabled for this browser. Please update your settings to use this feature'
		}
	}
	function eventListMapInit() { // copy data from fallback event listing markup
		var eventListingTableData = document.getElementsByClassName('event');

		for (var i = 0; i < eventListingTableData.length; i++) {
			var eventProfile = new Object,
				tableInfo = eventListingTableData[i].querySelectorAll('[data-head]');

			eventProfile.social = new Array;

			for (var j = 0; j < eventListingTableData[i].attributes.length; j++) {
				eventProfile[eventListingTableData[i].attributes[j].name] = eventListingTableData[i].attributes[j].value;
			}

			for (var l = 0; l < tableInfo.length; l++) {
				if (tableInfo[l].dataset.head === 'Event Date') {
					for (var propVal in tableInfo[l].dataset) {
						eventProfile[propVal.toLowerCase()] = tableInfo[l].dataset[propVal];
					}
					eventProfile.formattedDate = tableInfo[l].textContent;
				} else if (tableInfo[l].dataset.head === 'Share') {
					var socialItems = tableInfo[l].getElementsByClassName('social');
					for (var f = 0; f < socialItems.length; f++) {
						eventProfile.social.push([socialItems[f].getAttribute('href'), socialItems[f].textContent]);
					}
				} else {
					eventProfile[tableInfo[l].dataset.head.toLowerCase()] = tableInfo[l].textContent;
				}
			}

			eventListMap.dataArr.push(eventProfile);
		}

		eventListMapMergeDuplicateEvtData();
		eventListMapRenderListing(eventListMap.eventsSort.dateAscending);
		eventListMapCreateEvtListItems(eventListMap.eventsSort.dateAscending);
	}

	function eventListMapMergeDuplicateEvtData() {
		// filter through events using the same lat long coordinates and merge them into a single object
		var duplicateCoordinates = eventListMapFindDuplicateLatLong(),
			uniqueEvents = [],
			duplicateEvents = [],
			baseArr = [];

		for (var i = 0; i < eventListMap.dataArr.length; i++) {
			var duplicatesFound = 0;

			for (var k = 0; k < duplicateCoordinates.length; k++) {
				if (eventListMapLatLongCompare([eventListMap.dataArr[i]['data-lat'], eventListMap.dataArr[i]['data-long']], duplicateCoordinates[k])) duplicatesFound++;
			}
			if (duplicatesFound == 0) {
				uniqueEvents.push(eventListMap.dataArr[i]);
			} else {
				duplicateEvents.push(eventListMap.dataArr[i]);
			}
		}

		var mergedArr = baseArr.concat(uniqueEvents);

		for (var j = 0; j < duplicateCoordinates.length; j++) {
			var evtContainer = new Object;
			evtContainer.events = new Array;

			for (var t = 0; t < duplicateEvents.length; t++) {
				if (eventListMapLatLongCompare(duplicateCoordinates[j], [duplicateEvents[t]['data-lat'], duplicateEvents[t]['data-long']])) evtContainer.events.push(duplicateEvents[t]);
			}

			eventListMapSortByDate(evtContainer.events);

			evtContainer['event name'] = evtContainer.events[0]['event name'];
			evtContainer['data-lat'] = evtContainer.events[0]['data-lat'];
			evtContainer['data-long'] = evtContainer.events[0]['data-long'];
			evtContainer['formattedDate'] = evtContainer.events[0]['formattedDate'];
			evtContainer['presentation time'] = evtContainer.events[0]['presentation time'];
			evtContainer['state'] = evtContainer.events[0]['state'];
			evtContainer['city'] = evtContainer.events[0]['city'];
			mergedArr.push(evtContainer);
		}

		eventListMap.eventsSort.dateAscending = eventListMapSortByDate(mergedArr.slice());
		eventListMap.eventsSort.dateDescending = eventListMapSortByDate(mergedArr.slice()).reverse();
		eventListMap.eventsSort.stateAscending = eventListMapSortByAlpha(mergedArr.slice(), 'state');
		eventListMap.eventsSort.stateDescending = eventListMapSortByAlpha(mergedArr.slice(), 'state').reverse();
	}

	function eventListMapRenderListing(eventArrData) {
		var sortedEventsPanel = document.getElementsByClassName('map-listing__list--sorted')[0];
		sortedEventsPanel.innerHTML = '';

		if (eventArrData.length === 0) {
			eventListMap.mapListingNav.style.display = 'none';
			sortedEventsPanel.style.display = 'none';

			var msgContainer = document.createElement('div');
			var msg = document.createElement('p');
			msg.textContent = 'There are no events scheduled at this time.';

			msgContainer.appendChild(msg);
			var mapListingWrapper = document.getElementsByClassName('map-listing__listWrapper')[0];
			mapListingWrapper.appendChild(msgContainer);

			msgContainer.setAttribute('class', 'map-listing__message');

			eventListMapShowLoadingOverlay(false);
		}
		else {
			for (var i = 0; i < eventArrData.length; i++) {
				var listItem = document.createElement('li'),
					evtName = document.createElement('h2'),
					evtTime = document.createElement('time'),
					evtLocation = document.createElement('p'),
					timeText = eventArrData[i].hasOwnProperty('events') ? "Multiple Events" : (eventArrData[i]['formattedDate'] + ', ' + eventArrData[i]['presentation time']),
					addressValue;

				evtName.appendChild(document.createTextNode(eventArrData[i]['event name']));
				evtName.setAttribute('class', 'map-listing__name');

				evtTime.appendChild(document.createTextNode(timeText));
				evtTime.setAttribute('class', 'map-listing__time');

				if (eventArrData[i]['city'].length > 0 && eventArrData[i]['state'].length > 0) {
					addressValue = document.createTextNode(eventArrData[i]['city'] + ', ' + eventArrData[i]['state']);
				}
				else {
					addressValue = document.createTextNode(eventArrData[i]['city'] + ' ' + eventArrData[i]['state']);
				}

				evtLocation.appendChild(addressValue);
				evtLocation.setAttribute('class', 'map-listing__location');

				listItem.appendChild(evtName);
				listItem.appendChild(evtTime);
				listItem.appendChild(evtLocation);

				listItem.setAttribute('class', 'map-listing__item map-listing__item--sorted');
				listItem.setAttribute('data-lat', eventArrData[i]['data-lat']);
				listItem.setAttribute('data-long', eventArrData[i]['data-long']);
				listItem.setAttribute('tabindex', 0);

				sortedEventsPanel.appendChild(listItem);
			}
		}
	}

	function eventListMapCreateEvtListItems(eventArrData) {
		var detailedEvtList = document.getElementsByClassName('map-listing__list--detailed')[0],
			generateMarkup = function (data) {
				var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
					listItem = document.createElement('li'),
					evtWrapper = document.createElement('dl'),
					evtName = document.createElement('dt'),
					evtAddressWrapper = document.createElement('dd'),
					evtAddress1 = document.createElement('span'),
					evtAddress2 = document.createElement('span'),
					evtDate = document.createElement('dd'),
					evtPresentTime = null,
					evtNetworkTime = null,
					evtSocialMediaContainer = document.createElement('ul'),
					evtRegister = document.createElement('p'),
					evtRegisterLink = document.createElement('a'),
					addressValue;

				if (data['city'].length > 0 && data['state'].length > 0) {
					addressValue = document.createTextNode(data['city'] + ', ' + data['state'] + ' ' + data['zip']);
				}
				else {
					addressValue = document.createTextNode(data['city'] + ' ' + data['state'] + ' ' + data['zip']);
				}

				evtName.appendChild(document.createTextNode(data['event name']));
				evtName.setAttribute('class', 'map-listing__evtName');

				evtAddress1.appendChild(document.createTextNode(data['address']));
				evtAddress2.appendChild(addressValue);
				evtAddressWrapper.appendChild(evtAddress1);
				evtAddressWrapper.appendChild(evtAddress2);
				evtAddressWrapper.setAttribute('class', 'map-listing__info map-listing__info--evtAddress');

				var dayOfEvt = new Date(data['formattedDate']).getDay();
				dayOfEvt = days[dayOfEvt];

				evtDate.appendChild(document.createTextNode(dayOfEvt + ', ' + data['formattedDate']));
				evtDate.setAttribute('class', 'map-listing__info map-listing__info--evtDate');

				if (data.hasOwnProperty('networking time') && data['networking time'] !== '') {
					evtNetworkTime = document.createElement('dd');
					evtNetworkTime.appendChild(document.createTextNode('Networking: ' + data['networking time']));
					evtNetworkTime.setAttribute('class', 'map-listing__info map-listing__info--evtNetwork');
				}

				if (data.hasOwnProperty('presentation time') && data['presentation time'] !== '') {
					evtPresentTime = document.createElement('dd'),
						evtPresentTime.appendChild(document.createTextNode('Presentation: ' + data['presentation time']));
					evtPresentTime.setAttribute('class', 'map-listing__info map-listing__info--evtPresentation');
				}

				for (var i = 0; i < data.social.length; i++) {
					var socialMediaItem = document.createElement('li'),
						socialMediaLink = document.createElement('a');

					socialMediaLink.appendChild(document.createTextNode(data.social[i][1]));
					socialMediaLink.setAttribute('href', data.social[i][0]);
					socialMediaItem.appendChild(socialMediaLink);
					socialMediaItem.setAttribute('class', 'map-listing__info map-listing__info--evtSocialItem icon social');
					evtSocialMediaContainer.appendChild(socialMediaItem);
					evtSocialMediaContainer.setAttribute('class', 'map-listing__evtSocial');
				}

				evtRegisterLink.appendChild(document.createTextNode('Register for Event'));
				evtRegisterLink.setAttribute('href', data['data-event-reg']);
				evtRegister.appendChild(evtRegisterLink);
				evtRegister.setAttribute('class', 'map-listing__register');

				evtWrapper.appendChild(evtName);
				evtWrapper.appendChild(evtAddressWrapper);
				evtWrapper.appendChild(evtDate);

				if (evtNetworkTime != null) evtWrapper.appendChild(evtNetworkTime);
				if (evtPresentTime != null) evtWrapper.appendChild(evtPresentTime);

				evtWrapper.appendChild(evtSocialMediaContainer);
				evtWrapper.appendChild(evtRegister);
				evtWrapper.setAttribute('class', 'map-listing__evtDetails');

				listItem.appendChild(evtWrapper);
				listItem.setAttribute('class', 'map-listing__item map-listing__item--detail');
				listItem.setAttribute('data-lat', data['data-lat']);
				listItem.setAttribute('data-long', data['data-long']);

				detailedEvtList.appendChild(listItem);

				eventListMap.mapBoundsAllMarkers.extend({
					lat: Number(data['data-lat']),
					lng: Number(data['data-long'])
				});
			};

		for (var i = 0; i < eventArrData.length; i++) {
			if (eventArrData[i].hasOwnProperty('events')) {
				for (var k = 0; k < eventArrData[i].events.length; k++) {
					generateMarkup(eventArrData[i].events[k]);
				}
			} else {
				generateMarkup(eventArrData[i]);
			}
		}

		eventListMapEventListSort();
		eventListMapFindUserLocation();
		eventListMapCloseDetailedEvents();
		eventListMapEventItemClickTrigger();
		eventListMapErrorModalControl();
	}

	function eventListMapEventListSort() {
		var sortOptions = document.getElementsByClassName('map-listing__sortListing')[0];
		sortOptions.addEventListener('change', function (e) {
			var sortType = sortOptions.children[sortOptions.selectedIndex].getAttribute('data-sort'),
				currentSortType = sortOptions.getAttribute('data-currentSort');

			if (currentSortType === sortType) return;

			eventListMapRenderListing(eventListMap.eventsSort[sortType]);
			sortOptions.setAttribute('data-currentSort', sortType);
		});
	}

	function eventListMapFindDuplicateLatLong() {
		// search through scrapped event data and hunt for multiple occurrences of 
		// lat and long coordinates. store them into an array to later merge into one object
		var allLatLong = [],
			duplicateLatLong = [];

		// get all event lat long coordinates
		for (var i = 0; i < eventListMap.dataArr.length; i++) {
			var itemCoordinates = [eventListMap.dataArr[i]['data-lat'], eventListMap.dataArr[i]['data-long']];
			allLatLong.push(itemCoordinates);
		}

		var comparisonArr = allLatLong.slice();

		for (var j = 0; j < allLatLong.length; j++) {
			var instanceCounter = 0;

			for (var t = 0; t < comparisonArr.length; t++) {
				// check how many times each lat long coordinate appears in the duplicate array
				if (eventListMapLatLongCompare(allLatLong[j], comparisonArr[t])) instanceCounter++;
			}

			// if the lat long coordinate appears more than once...
			if (instanceCounter > 1) {
				if (duplicateLatLong.length >= 1) {
					// if the duplicateLatLong array isn't empty, check to see if the lat long coordinate you want to place in there already exists
					var intsCounter = 0;
					for (var z = 0; z < duplicateLatLong.length; z++) {
						if (eventListMapLatLongCompare(allLatLong[j], duplicateLatLong[z])) intsCounter++;
					}
					// if it doesn't, go ahead and push
					if (intsCounter == 0) duplicateLatLong.push([allLatLong[j][0], allLatLong[j][1]]);
				} else if (duplicateLatLong.length == 0) {
					// if there are no lat long coordinates in the duplicate lat long array, push it right away
					duplicateLatLong.push([allLatLong[j][0], allLatLong[j][1]]);
				}
			}
		}

		return duplicateLatLong;
	}

	function eventListMapFindUserLocation() {
		if ('geolocation' in navigator) {
			eventListMap.geoLocaterBtn.addEventListener('click', function () {
				eventListMapShowLoadingOverlay(true);

				if (eventListMap.eventsClosestToUser != null) {
					// if the user had already clicked for nearby events, show them the saved data, instead of making another request
					eventListMapShowDetailedEvents(eventListMap.eventsClosestToUser)
					map.fitBounds(eventListMap.mapBoundsClosestToUser);
					eventListMapShowLoadingOverlay(false);
				} else {
					// otherwise, make the request to use the distance matrix api
					var service = new google.maps.DistanceMatrixService;
					navigator.geolocation.getCurrentPosition(function (position) {

						var userLatLng = {
							lat: Number(position.coords.latitude.toFixed(6)),
							lng: Number(position.coords.longitude.toFixed(6))
						},
							formatEventsLatLng = eventListMap.eventsSort.dateAscending.map(function (x) {
								return {
									lat: Number(x['data-lat']),
									lng: Number(x['data-long'])
								}
							});

						service.getDistanceMatrix({
							origins: [userLatLng],
							destinations: formatEventsLatLng,
							travelMode: 'DRIVING',
							unitSystem: google.maps.UnitSystem.METRIC,
							avoidHighways: false,
							avoidTolls: false
						}, function (response, status) {
							if (status !== 'OK') {
								error.log('Error was: ' + status);
								eventListMapShowErrorModal(true, eventListMap.dictionary.noLocationsFound);
							} else {
								var destinationList = response.rows[0].elements,
									miles50 = 80467.2,
									miles100 = 160934,
									eventsIn50 = [],
									eventsIn100 = [];

								var modifiedEventsArr = eventListMap.eventsSort.dateAscending.slice();

								for (var i = 0; i < destinationList.length; i++) {
									if (destinationList[i].status === 'OK') {
										var latLongCopy = [modifiedEventsArr[i]['data-lat'], modifiedEventsArr[i]['data-long']];

										if (destinationList[i].distance.value <= miles50) eventsIn50.push(latLongCopy);
										if (destinationList[i].distance.value <= miles100) eventsIn100.push(latLongCopy);
									}
								}

								var convertedEventsLatLng = null,
									mapClosestEvents = function (arr) {
										eventListMap.eventsClosestToUser = arr;
										eventListMapShowDetailedEvents(arr);
										convertedEventsLatLng = arr.map(function (x) {
											return {
												lat: Number(x[0]),
												lng: Number(x[1])
											}
										});
									}

								if (eventsIn50.length > 0) {
									mapClosestEvents(eventsIn50);
								} else if (eventsIn100.length > 0) {
									mapClosestEvents(eventsIn100);
								} else {
									eventListMapShowErrorModal(true, eventListMap.dictionary.noLocationsFound);
								}

								if (convertedEventsLatLng != null) {
									//  create a new instance for map bounds
									var newMapBounds = new google.maps.LatLngBounds();

									for (var i = 0; i < convertedEventsLatLng.length; i++) {
										newMapBounds.extend(convertedEventsLatLng[i]);
									}

									// we'll use this to center the map around the closest events in case the user decides to use the geolocation service again
									eventListMap.mapBoundsClosestToUser = newMapBounds;
									map.fitBounds(newMapBounds);
								}
							}
							eventListMapShowLoadingOverlay(false);
						});
					}, function (error) {
						if (error.code === 1) {
							// if the user denies permission to use geolocation...
							eventListMapShowLoadingOverlay(false);
							eventListMapShowErrorModal(true, eventListMap.dictionary.permissionIssue);
						}
					});
				}
			});
		} else {
			eventListMap.geoLocaterBtn.style.display = 'none';
		}
	}

	function eventListMapCloseDetailedEvents() {
		eventListMap.closeBtn.addEventListener('click', function () {
			if (window.matchMedia("(max-width: 60.625em)").matches) document.documentElement.classList.remove('map-active');

			eventListMap.detailedEventsPanel.classList.remove('map-listing__detail--show');

			map.fitBounds(eventListMap.mapBoundsAllMarkers);
		});
	}

	function eventListMapEventItemClickTrigger() {
		var eventsArr = ['click', 'keypress'];

		for (var i = 0; i < eventsArr.length; i++) {
			eventListMap.sortedEventsPanel.addEventListener(eventsArr[i], function (e) {
				if (e.type == 'keypress') {
					if (e.keyCode != 13 || e.which != 13) return;
				}

				var item;

				if (e.target.classList.contains('map-listing__list')) {
					return;
				} else if (e.target.classList.contains('map-listing__item')) {
					item = e.target;
				} else {
					item = e.target.parentNode;
				}

				var latLong = [Number(item.getAttribute('data-lat')), Number(item.getAttribute('data-long'))];

				if (window.matchMedia("(min-width: 60.625em)").matches) {
					map.setCenter({ lat: latLong[0], lng: latLong[1] });
					map.setZoom(8);
				}

				eventListMapShowDetailedEvents([latLong]);
			});
		}
	}

	function eventListMapShowDetailedEvents(arrlatLong) {
		if (eventListMap.detailedEventsPanel.classList.contains('map-listing__detail--show') === false) {
			if (window.matchMedia("(max-width: 60.625em)").matches) document.documentElement.classList.add('map-active');

			eventListMap.detailedEventsPanel.classList.add('map-listing__detail--show');
		}

		var detailedEvtItems = document.getElementsByClassName('map-listing__item--detail');

		if (arrlatLong.length === 1) {
			for (var i = 0; i < detailedEvtItems.length; i++) {
				var itemLatLong = [detailedEvtItems[i].getAttribute('data-lat'), detailedEvtItems[i].getAttribute('data-long')];
				if (eventListMapLatLongCompare(arrlatLong[0], itemLatLong)) {
					detailedEvtItems[i].classList.add('map-listing__item--show');
				} else {
					detailedEvtItems[i].classList.remove('map-listing__item--show');
				}
			}
		} else {
			for (var i = 0; i < detailedEvtItems.length; i++) {
				detailedEvtItems[i].classList.remove('map-listing__item--show');
				var itemLatLong = [detailedEvtItems[i].getAttribute('data-lat'), detailedEvtItems[i].getAttribute('data-long')];
				for (var j = 0; j < arrlatLong.length; j++) {
					if (eventListMapLatLongCompare(arrlatLong[j], itemLatLong)) {
						detailedEvtItems[i].classList.add('map-listing__item--show');
					}
				}
			}
		}
	}

	function eventListMapShowLoadingOverlay(boolean) {
		if (boolean) {
			eventListMap.loadingOverlay.classList.remove('events-map__overlay--hide');
		} else {
			eventListMap.loadingOverlay.classList.add('events-map__overlay--hide');
		}
	}

	function eventListMapErrorModalControl() {
		var closeBtn = eventListMap.errorModal.getElementsByClassName('events-map__modalBtn')[0];

		closeBtn.addEventListener('click', function () {
			eventListMapShowErrorModal(false);
		});
	}

	function eventListMapShowErrorModal(boolean, errorMsg) {
		var modal = eventListMap.errorModal,
			msg = modal.getElementsByClassName('events-map__warning')[0];

		if (boolean) {
			modal.classList.add('events-map__errorModal--show');
			msg.textContent = errorMsg;
		} else {
			modal.classList.remove('events-map__errorModal--show');
			msg.textContent = '';
		}
	}

	function eventListMapSortByAlpha(arr, property) {
		arr.sort(function (a, b) {
			var itemA = a[property];
			var itemB = b[property];
			if (itemA < itemB) {
				return -1;
			}
			if (itemA > itemB) {
				return 1;
			}

			return 0;
		});

		return arr;
	}

	function eventListMapSortByDate(arr) {
		arr.sort(function (a, b) {
			var evtDate1 = new Date(a.formattedDate).getTime(),
				evtDate2 = new Date(b.formattedDate).getTime();

			if (evtDate1 == evtDate2) {
				var evtTime1 = new Date(a['presentation Time']).getTime(),
					evtTime2 = new Date(b['presentation Time']).getTime()

				return evtTime1 - evtTime2;
			}

			return evtDate1 - evtDate2;
		});

		return arr;
	}

	function eventListMapLatLongCompare(coordinates1, coordinates2) {
		return (Number(coordinates1[0]) === Number(coordinates2[0]) && Number(coordinates1[1]) === Number(coordinates2[1]));
	}

	$('td[data-head="Address"]').each(function (k, v) {
		var $this = $(this),
			myEvent = $this.parent('tr'),
			myLocation = myEvent.data('address'),
			latlong,
			myEventName = myEvent.find('[data-head="Event Name"]').text(),
			mySummary = '<b>' + myEvent.find('[data-head="Event Name"]').text() + '</b> - ' + myEvent.find('[data-head="Date"]').text() + '<br>' + myEvent.data('address') + '<br><a href="' + $this.parent('tr').data('event-reg') + '" target="_blank">Click here to register for this event</a>',
			infowindow = new google.maps.InfoWindow({
				content: mySummary
			}),
			eventCount = $('td[data-head="Address"]').length - 1;

		if (myEvent.data('lat') === undefined || myEvent.data('lat') === '' || myEvent.data('long') === undefined || myEvent.data('long') === '') {
			// Limit to try and overcome Google's rate limit
			setTimeout(function () {
				swEventsUtilGMapsHelpersGetLocationLatLong(myLocation, function (result) {
					myEvent.data('lat', result.G).data('long', result.K);
					myEvent.attr('data-lat', result.G).attr('data-long', result.K);
					swEventsUtilGMapsHelpersCreateMarker({
						markers: markers,
						map: map,
						markerCount: k,
						lat: result.G,
						lng: result.K,
						eventName: myEventName,
						info: infowindow,
						showInfo: false
					});
				});
			}, k * 250);
		} else {
			swEventsUtilGMapsHelpersCreateMarker({
				markers: markers,
				map: map,
				markerCount: k,
				lat: myEvent.data('lat'),
				lng: myEvent.data('long'),
				eventName: myEventName,
				info: infowindow,
				showInfo: false,
				customEvent: function () {
					var self = this,
						coordinates = [self.lat, self.lng];

					eventListMapShowDetailedEvents([coordinates]);
				}
			});
		}

		if (k === eventCount) {
			$('.map').removeClass('notLoaded');
			eventListMapShowLoadingOverlay(false);
		}

		$this.parent('tr').on('click', function () {
			//console.log('href: ', $(this).attr('data-event-reg'));

			window.open($(this).attr('data-event-reg'));
		});
	});

	eventListMapInit();
};

function swEventsUtilPointsBreakInit(context) {
	var doIt;
	doWork(context);
	window.onresize = function () {
		clearTimeout(doIt);
		doIt = setTimeout(function () { doWork(context); }, time);
	};
	return this;
}

function doWork(context) {
	var time = 200;
	var W, H;
	var andReg = /&&+/g;
	W = window.innerWidth || document.documentElement.clientWidth;
	H = window.innerHeight || document.documentElement.clientHeight;
	for (var i in context) {
		if (i.charAt(0) === 'W' || i.charAt(0) === 'H') {
			i = i.replace(/\s+/g, '').toUpperCase();
			var f = 0,
				myFunc = context[i];
			if (i.match(andReg) !== null) {
				var tests = i.split(/&&/);
				for (var x in tests) {
					if (tests[x].charAt(0) === 'W' || tests[x].charAt(0) === 'H') {
						if (breakPointTest(tests[x], W, H) === false) { f = false; }
					}
				}
				if (f !== false) { runThis(myFunc(W, H)); }
			} else {
				if (breakPointTest(i, W, H)) { runThis(myFunc(W, H)); }
			}
		}
	}
}

function breakPointTest(bpTest, W, H) {
	var type = bpTest.charAt(0) === 'W' ? W : H,
		test = bpTest[1],
		dims = bpTest.split(bpTest.charAt(1))[1];
	return test === '>' && type > dims || test === '<' && type < dims ? true : false;
}

function runThis(fin) {
	if (typeof fin === 'function') { fin(); }
}

function swEventsUtilJQueryExtendsKThen() {
	jQuery.fn.kThen = function (time, func, selector) {
		var me = this, t = typeof time === 'function' ? 0 : time,
			f = typeof time === 'function' ? time : func; setTimeout(function () { if (typeof f === 'function') { f.apply(me); } }, t); return selector ? $(selector) : me;
	};
}

function swEventsUtilTableSortrInit(opts) {
	var theTable, ignoreHeading, useUTC;

	opts = opts || {};
	theTable = opts.tableSelector || 'table.sortable';
	ignoreHeading = opts.ignoreHeading || '.sortIgnore';
	swEventsUtilTableSortrInitSetup(theTable, ignoreHeading, useUTC);
}

function swEventsUtilTableSortrInitSetup(theTable, ignoreHeading, useUTC) {
	$(theTable).each(function () {
		$(this).find('thead th').not(ignoreHeading).on('click', function () {
			var $this = $(this),
				colIdx = $this.index(),
				toSort = [],
				curTblBod = $this.parents('table').find('tbody'),
				newTblBod = $('<tbody>');

			if ($(this).attr('data-utc')) {
				useUTC = true;
			}

			$this.toggleClass('toggle');

			curTblBod.find('tr').each(function (k, v) {
				if (useUTC === true) {
					toSort.push([$(this).children('td').eq(colIdx).attr('data-utc'), $(this).index()]);
				} else {
					toSort.push([$(this).children('td').eq(colIdx).text(), $(this).index()]);
				}
			});

			toSort.sort();

			if ($(theTable).data('sorted') === 'descending') {
				toSort.reverse();
				$(theTable).data('sorted', 'ascending');
			} else {
				$(theTable).data('sorted', 'descending');
			}

			console.log($(theTable).data('sorted'));
			console.log("toSort: ", toSort);

			$.each(toSort, function (k, v) {
				curTblBod.find('tr').eq(v[1]).clone(true).appendTo(newTblBod);
			});
			curTblBod.replaceWith(newTblBod);
		});
	});
}


function swEventsUtilGMapsHelpersGetLocationLatLong(address, cb) {
	var latlong;
	jQuery.support.cors = true;
	var geocoder = new google.maps.Geocoder();

	geocoder.geocode({ 'address': address }, function (results, status) {
		if (status === 'OK') {
			latlong = {
				lat: parseFloat(results[0].geometry.location.lat()),
				lng: parseFloat(results[0].geometry.location.lng())
			};

			cb(latlong);
		}
		else {
			console.log("GMaps Geocode ERROR");
		}
	});
}

function swEventsUtilGMapsHelpersCreateMarker(opts) {
	swEventsUtilJQueryExtendsKThen();
	opts.markers[opts.markerCount] = new google.maps.Marker({
		position: {
			lat: parseFloat(opts.lat),
			lng: parseFloat(opts.lng)
		},
		map: opts.map,
		title: opts.eventName
	});
	if (opts.showInfo === true) {
		opts.markers[opts.markerCount].addListener('click', function () {
			$('html,body').animate({ scrollTop: $('.map').offset().top - 50 }, 200, function () {
				opts.map.setZoom(8);
				opts.map.setCenter(opts.markers[opts.markerCount].getPosition());
				opts.info.open(opts.map, opts.markers[opts.markerCount]);
			});
		});
	} else if (opts.showInfo === false && typeof opts.customEvent === 'function') {
		opts.markers[opts.markerCount].addListener('click', function () {
			opts.map.setZoom(8);
			opts.map.setCenter(opts.markers[opts.markerCount].getPosition());
			opts.customEvent(opts.markers[opts.markerCount]);
		});
	}
}