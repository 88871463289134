import { DataTable } from "simple-datatables";

// Registrant table sorting
(function () {
	let el = document.querySelectorAll('.full.regAttendees');

	if (el == undefined) { return }

	el.forEach(e => init(e));

	function init(el) {
		const dataTable = new DataTable(el, {
			searchable: false,
			paging: false
		});
	}

	if ($('[data-print-style]').length) {
		$('head').append('<link rel="stylesheet" media="print" type="text/css" href="' + $('[data-print-style]').data('print-style') + '">');
	}

	$('.icon-printer').on('click', function () {
		$('.printDetails').show();
	});

	$('.printDetails__print-button').on('click', function () {
		var header = $('.mainHeader').html(),
			body = $('.mainContent').html(),
			styles = $('link'),
			printWindow = window.open("", "", "");

		printWindow.document.write('<html><head>');
		styles.each(function (i) {
			printWindow.document.write('<link rel="stylesheet" type="text/css" href="', styles[i].href, '" />');
		});
		printWindow.document.write('<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js"></script>');
		printWindow.document.write('<script type="text/javascript">$(window).load(function(){window.print();});</script>');
		printWindow.document.write('</head><body class="eventDetails dashboard loaded"><header class="mainHeader">');
		printWindow.document.write(header);
		if ($('.presenterDetails').length <= 0) {
			printWindow.document.write('</header><div class="mainContent noPresenter">');
		}
		else {
			printWindow.document.write('</header><div class="mainContent">');
		}
		printWindow.document.write(body);
		printWindow.document.write('</div></body></html>');
		printWindow.document.close();
		printWindow.focus();
	});

	//var mapSrc = swEvents.util.gMapsHelpers.getMapImgUrl({
	//	addr: $('.venueMeta').find('address').text(),
	//	zoom: '12',
	//	size: '480x480',
	//	color: 'blue'
	//});

	//$('.venueMeta').find('.map').prop('src', mapSrc).on('click', function () {
	//	if (!$('.modal img').length) {
	//		var img = $('<img class="map">').attr('src', swEvents.util.gMapsHelpers.getMapImgUrl({
	//			addr: $(this).parents('.venueMeta').find('address').text(),
	//			zoom: swEvents.util.pointsBreak.getWidth() <= 500 ? '16' : '17',
	//			size: swEvents.util.pointsBreak.getWidth() <= 500 ? '420x420' : '720x720',
	//			color: 'blue'
	//		}));
	//		$('.modal .modalInner').append(img);
	//	}
	//	$('.modal').showModal().on('modalClosed', function () {
	//		$(this).off('modalClosed').find('img').remove();
	//	}).find('.closeBtn').on('click', function (e) {
	//		e.preventDefault();
	//		$(this).off('click');
	//		$('.modal').hideModal();
	//	});
	//});

	if (document.getElementsByClassName('cancel-prompt')[0]) {
		var cancelPrompt = {
			eventName: $('.eventName').text(),
			postURL: $('button[data-action="cancel-event"]').attr('href'),
			textInput: $('#confirmation'),
			submitBtn: $('a[data-action="cancel-event"]'),
			exitBtn: $('button[data-action="exit-prompt"]'),
			reset: function () {
				this.textInput[0].value = '';
				this.submitBtn.removeClass('btn--red');
			}
		};


		$(cancelPrompt.submitBtn).on('click', function (e) {
			cancelPrompt.submitBtn.replaceWith('<a href="#" class="btn btn--red" data-action="cancel-event">Cancelling... <span class="icon icon-trash"></span></a>');
		});

		$('.cancel-prompt__eventName').text(cancelPrompt.eventName);

		cancelPrompt.textInput.on('keyup', function (e) {
			if (this.value === 'CONFIRM') {
				cancelPrompt.submitBtn.addClass('btn--red')
			} else if (cancelPrompt.submitBtn.hasClass('btn--red')) {
				cancelPrompt.submitBtn.removeClass('btn--red');
			}
		});

		$('a[data-context="cancel-event"]').on('click', function (e) {
			e.preventDefault();

			$('.cancel-prompt').showModal().on('modalClosed', function () {
				cancelPrompt.reset();
			}).find('.closeBtn').on('click', function (e) {
				e.preventDefault();
				$(this).off('click');
				$('.cancel-prompt').hideModal();
				cancelPrompt.reset();
			});
		});

		cancelPrompt.exitBtn.on('click', function (e) {
			e.preventDefault();
			$('.cancel-prompt').hideModal();
			cancelPrompt.reset();
		});
	}

	$('.eventDetails .selectedTemplate .btn.icon-envelop').on('click', function (e) {
		e.preventDefault();
		var myId = $(this).data('eid');
		$.ajax({
			url: sendproofurl + '?eid=' + myId,
			method: 'POST',
			complete: function (data) {
				console.log(data);
				//data = JSON.parse(data);
				if (data.IsError !== false) {
					$('.eventDetails .selectedTemplate .btn.icon-envelop').text('Proof Sent');
				} else {
					alert('There was an error\n' + data.responseJSON.Message);
				}
			}
		});
	});


	//for clicking the download button
	var dlBtn,
		table,
		fileName,
		csv = '';
	swEventsUtilCsvDownloaderInit({
		downloadButton: '.listDownload--registrants',
		table: '.dlCSV',
		fileName: 'Attendees' + $('.eventMeta').find('.eventName').text().replace(/\s/g, '') + '.csv'
	});

	function swEventsUtilCsvDownloaderInit(opts) {
		opts = opts || {};
		dlBtn = opts.downloadButton || '.csvDownload';
		table = opts.table || '.csvData';
		fileName = opts.fileName || 'CSV-Download';
		makeCsv();
	}

	function makeCsv() {
		$(table).find('thead th').each(function (i) {
			csv += '"' + $(this).text() + '"' + (i === ($(table).find('thead th').length - 1) ? '\r\n' : ',');
		}).end().find('tbody tr').each(function (i) {
			$(this).find('td').each(function (i) {
				csv += '"' + $(this).text() + '"' + (i === ($(this).parent('tr').find('td').length - 1) ? '\r\n' : ',');
			});
		});
		setupBtn(csv);
	}

	function setupBtn(data) {
		$(dlBtn).attr({
			'href': 'data:application/text;charset=utf-8,' + encodeURIComponent(data),
			'target': '_blank',
			'download': fileName
		});
		if (window.navigator.userAgent.search('Trident') >= 0 || window.navigator.userAgent.search('Edge')) {
			$(dlBtn).on('click', function (e) {
				$('body').append('<iframe id="csvFrame" style="display: none;"></iframe>');
				var iframe = $('#csvFrame')[0];
				iframe = iframe.contentWindow || iframe.contentDocument;
				data = 'sep=,\r\n' + data;
				iframe.document.open('text/html', 'replace');
				iframe.document.write(data);
				iframe.document.close();
				iframe.focus();
				iframe.document.execCommand('SaveAs', true, 'Attendees.csv');
				if (window.navigator.userAgent.search('Edge')) {
					var blobObject = new Blob([data]);
					window.navigator.msSaveOrOpenBlob(blobObject, "Attendees.csv");
				}
				$('#csvFrame').remove();
				e.preventDefault();
			});
		}
	}
}());